import React, { lazy, useMemo } from "react";
import { PropertyContext } from "properties/propertyPage/PropertyContext";
import { PropertyStore } from "properties/_stores/PropertyStore";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import dayjs from "dayjs";
import i18next from "i18next";
import { PropertyMeta } from "properties/propertyPage/PropertyMeta";
import { PropertyBreadcrumb } from "properties/propertyPage/PropertyBreadcrumb";
import { PropertyPriceAndAddress } from "properties/propertyPage/PropertyPriceAndAddress";
import { PropertyDetails } from "properties/propertyPage/PropertyDetails";
import { PropertyCollapsible } from "properties/propertyPage/PropertyCollapsible";
import { PropertyContactForm } from "properties/propertyPage/PropertyContactForm";
import { PropertyMapRender } from "properties/propertyPage/PropertyMapRender";
import Suspenser from "_common/loaders/Suspenser";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";

dayjs.locale(i18next.language);

export const PROPERTY_VIEW_REF: { current: HTMLDivElement | null } = {
    current: null,
};

// const LazyPropertyOrUnitSideBar = lazy(() => import("properties/propertyPage/PropertyOrUnitSideBar"));
const LazyPropertyOrUnitGalleryPage = lazy(() => import("properties/gallery/PropertyOrUnitGalleryPage"));
// const LazyPropertyOrUnitGalleryImageModal = lazy(() => import("properties/gallery/PropertyOrUnitGalleryImageModal"));
const LazyPropertySimilarProperties = lazy(() => import("properties/propertyPage/PropertySimilarProperties"));
type Props = {
    property: TPropertyListingMdl;
};

export function PropertyView(props: Props) {
    const propertyStore = useMemo(() => new PropertyStore(props.property), [props.property]);
    // const unitsInInventory = getAllAvailableUnitsOfInventory(propertyStore.property.inventory);
    return (
        // <PropertyContext propertyStore={propertyStore}>
        //     <Helmet>
        //         <link rel="preload" as="image" href={props.property?.photos?.[0]?.url} />
        //         <link rel="preload" as="image" href={props.property?.photos?.[0]?.thumbnail} />
        //         <link
        //             rel="preload"
        //             as="image"
        //             href={props.property?.photos?.[0]?.url}
        //             imagesrcset={`${props.property?.premiumPhotos?.[0]?.sm ?? props.property?.photos?.[0]?.url} 600w, ${
        //                 props.property?.premiumPhotos?.[0]?.md ?? props.property?.photos?.[0]?.url
        //             } 960w, ${props.property?.premiumPhotos?.[0]?.lg ?? props.property?.photos?.[0]?.url} 1920w`}
        //             sizes={"100vw"}
        //         />
        //     </Helmet>
        //     <div className={styles.mobileGallery}>
        //         <PropertyOrUnitGalleryMobile
        //             resource={RESOURCE.PROPERTIES}
        //             store={propertyStore}
        //             numberOfUnitLeft={unitsInInventory.length ?? 0}
        //         />
        //     </div>
        //     <Suspenser noSpinner noText>
        //         <LazyPropertyOrUnitSideBar resource={RESOURCE.PROPERTIES} propertyOrUnit={props.property} />
        //     </Suspenser>
        //     <div className={styles.container}>
        //         <PropertyMeta property={props.property} />
        //         <div className={"flex flex-row"}>
        //             <div className={clsx(styles.sectionsColumn, "flex-1")}>
        //                 <div className={styles.breadcrumb}>
        //                     <PropertyBreadcrumb />
        //                 </div>
        //                 <PropertySections />
        //             </div>
        //             <div ref={PROPERTY_VIEW_REF} className={clsx(styles.gallery, "flex-1")}>
        //                 <PropertyOrUnitGallery
        //                     resource={RESOURCE.PROPERTIES}
        //                     store={propertyStore}
        //                     numberOfUnitLeft={unitsInInventory.length ?? 0}
        //                 />
        //             </div>
        //         </div>
        //     </div>
        //     <Suspenser>
        //         <LazyPropertyOrUnitGalleryPage resource={RESOURCE.PROPERTIES} />
        //     </Suspenser>
        //     <Suspenser>
        //         <LazyPropertyOrUnitGalleryImageModal resource={RESOURCE.PROPERTIES} store={propertyStore} />
        //     </Suspenser>
        //     <PropertyModalInfos />
        // </PropertyContext>

        <PropertyContext propertyStore={propertyStore}>
            <div className="mt-0 mx-0 mb-5 md:mt-2 md:mx-0 lg:mx-[100px] lg:mb-5">
                <PropertyMeta property={props.property} />
                <div className={"hidden md:block"}>
                    <PropertyBreadcrumb />
                </div>
                <div className="flex flex-col mt-[37px] md:flex-row md:gap-12 md:mt-[51px]">
                    <div className="flex-1 flex flex-col gap-[41px] md:w-2/3">
                        <PropertyPriceAndAddress property={propertyStore.property} />
                        <PropertyDetails property={propertyStore.property} />
                        <PropertyCollapsible property={propertyStore.property} />
                        <PropertyMapRender />
                        <Suspenser noSpinner noText>
                            <LazyPropertySimilarProperties />
                        </Suspenser>
                        <Suspenser>
                            <LazyPropertyOrUnitGalleryPage resource={RESOURCE.PROPERTIES} />
                        </Suspenser>
                    </div>

                    <div className="relative md:w-1/3 mt-2.5">
                        <PropertyContactForm property={propertyStore.property} />
                    </div>
                </div>
            </div>
        </PropertyContext>
    );
}
