import React from "react";
import { useTranslation } from "react-i18next";
import { isInPesosProperty } from "_common/_utils/propertyUtils";
import { formatPesosPrice, formatPrice } from "_common/_utils/currencyUtils";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { PropertyCardPrice } from "properties/propertyCard/components/PropertyCardPrice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import location from "../../assets/images/icons/primaryLocation.svg";

type Props = {
    property: TPropertyListingMdl;
};

export function PropertyPriceAndAddress(props: Props) {
    const { t } = useTranslation();

    const sortAddress = [
        props.property.address.neighbourhood,
        props.property.address.city,
        t("provinces." + props.property.address.province.toUpperCase()),
        t("propertyPage.country." + (props.property.address.country ?? "MEX")),
    ];
    const completeAddress = sortAddress
        .filter((addressPart) => addressPart !== undefined && addressPart !== "")
        .slice(0)
        .join(", ");

    return (
        <div className={"flex flex-col gap-5"}>
            <div className={"font-semibold text-xl ml-2.5"}>
                {props.property.priceIsNotDisplayed ? (
                    <>{t("propertiesPage.card.priceOnRequest")}</>
                ) : (
                    <>
                        {t("words.fromWithColon")}
                        {isInPesosProperty(props.property) ? (
                            <>{formatPesosPrice(props.property.priceInPesos?.min)}</>
                        ) : (
                            <>{formatPrice(props.property.price?.min, false, false)}</>
                        )}
                    </>
                )}
            </div>

            <div className={"ml-2.5"}>
                <PropertyCardPrice property={props.property} />
            </div>

            <div className={"flex items-center"}>
                <LazyLoadImage src={location} />
                {completeAddress}
            </div>
        </div>
    );
}
