import React from "react";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Input } from "_common/ui/forms/Input";
import { InputPhone } from "_common/ui/forms/InputPhone";
import { Checkbox } from "_common/ui/mui/checkbox/Checkbox";
import { TextArea } from "_common/ui/forms/TextArea";
import { HiddenFieldsAttributerForm } from "components/form/HiddenFieldsAttributerForm";
import { useTranslation } from "react-i18next";
import { TContactForm } from "components/misc/contactUs/ContactFormComponent";
import { userStore } from "users/_stores/userStore";
import { useWindowSize } from "_common/_utils/hookWindow";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { PropertyFormBtn } from "properties/propertyPage/PropertyFormBtn";

type Props = {
    property: TPropertyListingMdl;
};

export function PropertyContactForm(props: Props) {
    const { t } = useTranslation();

    const form = useForm<TContactForm>({
        defaultValues: {
            name: userStore.isLogged ? userStore.user?.firstName + " " + userStore.user?.lastName : "",
            phone: userStore.isLogged ? userStore.user?.phone : "",
            email: userStore.isLogged ? userStore.user?.email : "",
            text: "",
            subject: "",
            inquiry: "",
            workingWithAnAgent: "",
            newsletter: false,
        },
        mode: "onBlur",
    });

    const onAgentChange = (value: string) => {
        form.setValue("workingWithAnAgent", value);
    };

    const onNewsletterChange = (value: boolean) => {
        form.setValue("newsletter", value);
    };

    const size = useWindowSize();

    return (
        <FormProvider {...form}>
            <div
                className={`sticky top-[130px] p-5 flex-3 shadow-[0px_0px_10px_0px_#1A3A4540] mt-5 mx-2.5 rounded-[var(--border-radius)] max-w-[500px] h-fit md:mt-0`}
            >
                <div className={"flex flex-col flex-1"}>
                    <div className={"flex flex-col gap-3.5"}>
                        <div className={"text-xl"}>
                            {t("propertyPage.contactForm.title", {
                                property: props.property.localized.title,
                            })}
                        </div>
                        <div className={"text-sm"}>{t("propertyPage.contactForm.subtitle")}</div>
                    </div>
                    <div className={"mt-3.5"}>
                        <InputBlock label={t("propertyPage.contactForm.fullName")} required />
                        <Controller
                            as={Input}
                            name="name"
                            control={form.control}
                            className={"!border !border-[var(--color-border)] !rounded-[var(--border-radius)]"}
                            placeholder={t("propertyPage.contactForm.fullName") + (size.width < 680 ? "*" : "")}
                        />
                        <InputBlock label={t("propertyPage.contactForm.email")} required className={"mt-[23px]"} />
                        <Controller
                            as={Input}
                            name="email"
                            control={form.control}
                            className={"!border !border-[var(--color-border)] !rounded-[var(--border-radius)]"}
                            placeholder={t("propertyPage.contactForm.email") + (size.width < 680 ? "*" : "")}
                            required
                            rules={{
                                required: t<string>("errors.forms.required"),
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: t("errors.forms.emailInvalid"),
                                },
                            }}
                        />

                        <div className={"mt-[23px]"}>
                            <div>{t("propertyPage.contactForm.workingWithAgent")}</div>
                            <Controller
                                as={
                                    <>
                                        <Checkbox
                                            checked={form.watch("workingWithAnAgent") === "yes"}
                                            onChange={() => onAgentChange("yes")}
                                        />
                                        <label className="mr-2">{t("words.yes")}</label>
                                        <Checkbox
                                            checked={form.watch("workingWithAnAgent") === "no"}
                                            onChange={() => onAgentChange("no")}
                                        />
                                        <label className="mr-2">{t("words.no")}</label>
                                        <Checkbox
                                            checked={form.watch("workingWithAnAgent") === "I am an agent"}
                                            onChange={() => onAgentChange("I am an agent")}
                                        />
                                        <label className="mr-2">{t("propertyPage.contactForm.iAmAgent")}</label>
                                    </>
                                }
                                name="workingWithAnAgent"
                                control={form.control}
                            />
                        </div>
                    </div>
                    <InputBlock label={t("propertyPage.contactForm.phone")} required className={"mt-[23px]"} />
                    <Controller
                        as={InputPhone}
                        name="phone"
                        control={form.control}
                        placeholder={t("propertyPage.contactForm.phone")}
                        className={"!border !border-[var(--color-border)] !rounded-[var(--border-radius)]"}
                    />
                    <div>
                        <InputBlock
                            label={t("propertyPage.contactForm.howCanAgentHelp")}
                            className={"mt-[23px] hidden md:block"}
                        />
                        <Controller
                            as={TextArea}
                            name="text"
                            control={form.control}
                            rows={3}
                            className={
                                "!border !border-[var(--color-border)] !rounded-[var(--border-radius)] mt-[23px] md:mt-0"
                            }
                            placeholder={"Message" + (size.width < 680 ? "*" : "")}
                        />
                    </div>
                </div>
                <div className="flex items-center mt-4">
                    <Controller
                        name="newsletter"
                        control={form.control}
                        render={({ onChange, value }) => (
                            <Checkbox
                                checked={value}
                                onChange={(e) => {
                                    onChange(e.target.checked);
                                    onNewsletterChange(e.target.checked);
                                }}
                            />
                        )}
                    />
                    <label className="ml-2 mt-3.5 max-w-[350px]">{t("propertyPage.contactForm.subscribe")}</label>
                </div>
                <div className={"flex-1 flex flex-col"}>
                    <PropertyFormBtn resource={RESOURCE.PROPERTIES} />
                </div>
            </div>
            <HiddenFieldsAttributerForm />
        </FormProvider>
    );
}
