import { useState } from "react";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { unitsStore } from "units/_stores/unitTypesStore";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { useParams } from "react-router";
import { reformatStringForUrls, reformatUrlForOriginalName } from "_common/_utils/alphaNumUtils";
import { useLocation } from "react-router-dom";
import sharedConfig from "_configs/sharedConfig";
import { URLS } from "_configs/URLS";
import {
    getMapCoordinatesFromParams,
    PARAMS_NORTH_PREFIX,
    PARAMS_PAGE_PREFIX,
    PARAMS_ZOOM_PREFIX,
} from "_common/_utils/searchUtils";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import { useTranslation } from "react-i18next";
import { isPropertyType } from "_common/_utils/filterUtils";
import { usePropertiesStore } from "properties/PropertiesPageContext";

export function usePropertyOrUnit(type: RESOURCE, itemId: string) {
    const itemOrFetch = RESOURCE.PROPERTIES === type ? propertiesStore.get(itemId) : unitsStore.get(itemId);
    const { loading } = useLoadingFromPromise(
        "_id" in itemOrFetch ? new Promise((resolve) => resolve(itemOrFetch)) : itemOrFetch,
    );

    if ("_id" in itemOrFetch) {
        return { itemOrFetch, loading: undefined };
    }
    return { itemOrFetch, loading };
}

export function useSearchPageParams() {
    // /region(p1)/city(p2)/neighbourhood(p3)/page(p4)/zoom(p5)
    // /region(p1)/city(p2)/beachFrontOrLuxuryOrTypeOrCommunities(p3)/page(p4)/zoom(p5)

    // /region(p1)/city(p2)/neighbourhood(p3)/page(p4)
    // /region(p1)/city(p2)/neighbourhood(p3)/zoom(p4)
    // /region(p1)/city(p2)/beachFrontOrLuxuryOrTypeOrCommunities(p3)/page(p4)
    // /region(p1)/city(p2)/beachFrontOrLuxuryOrTypeOrCommunities(p3)/zoom(p4)
    // /region(p1)/city(p2)/page(p3)/zoom(p4)
    // /region(p1)/beachFrontOrLuxuryOrTypeOrCommunities(p2)/page(p3)/zoom(p4)

    // /region(p1)/city(p2)/neighbourhood(p3)
    // /region(p1)/city(p2)/beachFrontOrLuxuryOrTypeOrCommunities(p3)
    // /region(p1)/city(p2)/page(p3)
    // /region(p1)/city(p2)/zoom(p3)
    // /region(p1)/beachFrontOrLuxuryOrTypeOrCommunities(p2)/page(p3)
    // /region(p1)/beachFrontOrLuxuryOrTypeOrCommunities(p2)/zoom(p3)
    // /region(p1)/page(p2)/zoom(p3)

    // /region(p1)/city(p2)
    // /region(p1)/beachFrontOrLuxuryOrTypeOrCommunities(p2)
    // /region(p1)/page(p2)
    // /region(p1)/zoom(p2)

    // /region(p1)

    // === !GEOZONE ===

    // /beachFrontOrLuxuryOrType(p1)/coordinate(p2)/page(p3)/zoom(p4)

    // /beachFrontOrLuxuryOrType(p1)/coordinate(p2)/page(p3)
    // /beachFrontOrLuxuryOrType(p1)/coordinate(p2)/zoom(p3)
    // /beachFrontOrLuxuryOrType(p1)/page(p2)/zoom(p3)
    // /coordinates(p1)/page(p2)/zoom(p3)

    // /beachFrontOrLuxuryOrType(p1)/coordinate(p2)
    // /beachFrontOrLuxuryOrType(p1)/page(p2)
    // /beachFrontOrLuxuryOrType(p1)/zoom(p2)
    // /coordinates(p1)/page(p2)
    // /coordinates(p1)/zoom(p2)
    // /page(p1)/zoom(p2)

    // /beachFrontOrLuxuryOrType(p1)
    // /coordinates(p1)
    // /page(p1)
    // /zoom(p1)

    const { i18n } = useTranslation();
    const propertiesStore = usePropertiesStore();
    const localizedBeachFront = reformatStringForUrls(getI18nExpByLang(i18n.language, "property.features.beachfront"));
    const localizedLuxury = reformatStringForUrls(getI18nExpByLang(i18n.language, "property.features.luxury"));
    const { p1, p2, p3, p4, p5 } = useParams();
    let cityForMeta,
        coordinates,
        propertyType,
        page,
        beachFrontOrLuxury,
        isAtLeastCityPage,
        isAtLeastNeighborhoodPage,
        isAtLeastProvincePage,
        isExistingPropertyType;

    const isGeoZonePage =
        p1 &&
        !p1?.startsWith(PARAMS_NORTH_PREFIX) &&
        !p1?.startsWith(PARAMS_ZOOM_PREFIX) &&
        !p1?.startsWith(PARAMS_PAGE_PREFIX) &&
        p1 !== localizedBeachFront &&
        p1 !== localizedLuxury &&
        !isPropertyType(p1);
    if (isGeoZonePage) {
        if (p5) {
            if (p5.startsWith(PARAMS_ZOOM_PREFIX)) {
                page = p5.replace(PARAMS_ZOOM_PREFIX, "");
            }
        }

        if (p4) {
            if (p4.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p4.replace(PARAMS_PAGE_PREFIX, "");
            }
        }

        if (p3) {
            if (p3 === localizedBeachFront || p3 === localizedLuxury) {
                beachFrontOrLuxury = p3;
            } else if (p3.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p3.replace(PARAMS_PAGE_PREFIX, "");
            } else if (isPropertyType(p3)) {
                propertyType = p3;
                const labelType = getI18nExpByLang(i18n.language, `property.types`);
                isExistingPropertyType = !Object.values(labelType).includes(labelType);
            } else {
                isAtLeastNeighborhoodPage = true;
            }
        }

        if (p2) {
            if (p2 === localizedBeachFront || p2 === localizedLuxury) {
                beachFrontOrLuxury = p2;
            } else if (p2.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p2.replace(PARAMS_PAGE_PREFIX, "");
            } else if (isPropertyType(p2)) {
                propertyType = p2;
                const labelType = getI18nExpByLang(i18n.language, `property.types`);
                isExistingPropertyType = !Object.values(labelType).includes(labelType);
            } else {
                isAtLeastCityPage = true;
                cityForMeta = reformatUrlForOriginalName(p2);
            }
        } else {
            cityForMeta = reformatUrlForOriginalName(p1);
        }
        isAtLeastProvincePage = true;
    } else if (!isGeoZonePage) {
        if (p3) {
            if (p3.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p3.replace(PARAMS_PAGE_PREFIX, "");
            }
        }

        if (p2) {
            if (p2.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p2.replace(PARAMS_PAGE_PREFIX, "");
            } else if (p2 === localizedBeachFront || p2 === localizedLuxury) {
                beachFrontOrLuxury = p2;
            } else if (p2.startsWith(PARAMS_NORTH_PREFIX)) {
                const _coordinates = p1.split(",");
                coordinates = getMapCoordinatesFromParams(_coordinates);
                propertiesStore.searchParams.mapCoordinates = coordinates;
            }
        }
        if (p1) {
            if (p1.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p1.replace(PARAMS_PAGE_PREFIX, "");
            } else if (p1 === localizedBeachFront || p1 === localizedLuxury) {
                beachFrontOrLuxury = p1;
            } else if (p1.startsWith(PARAMS_NORTH_PREFIX)) {
                const _coordinates = p1.split(",");
                coordinates = getMapCoordinatesFromParams(_coordinates);
                propertiesStore.searchParams.mapCoordinates = coordinates;
            } else if (isPropertyType(p1)) {
                propertyType = p1;
                const labelType = getI18nExpByLang(i18n.language, `property.types`);
                isExistingPropertyType = !Object.values(labelType).includes(labelType);
            }
        }
    }
    return {
        isExistingPropertyType,
        cityForMeta,
        page,
        propertyType,
        beachFrontOrLuxury: beachFrontOrLuxury,
        isLuxury: beachFrontOrLuxury === localizedLuxury,
        isBeachfront: beachFrontOrLuxury === localizedBeachFront,
        isAtLeastCityPage,
        isAtLeastNeighborhoodPage,
        isAtLeastProvincePage,
    };
}

export function useHomePage() {
    const url = useLocation().pathname;
    const urlSplit = url.split("/");
    return urlSplit.length < 3 && (urlSplit[1] === "" || Object.keys(sharedConfig.languages).includes(urlSplit[1]));
}

export function usePage() {
    const url = useLocation().pathname;
    const isHomePage = useHomePage();
    const isBuyPage = url.startsWith(URLS.buy());
    const isPropertyUnitPage = url.startsWith(URLS.property(""));
    return {
        isHomePage,
        isBuyPage,
        isPropertyUnitPage,
    };
}

export function useForceRefreshPage(initUrl?: string) {
    const location = useLocation();
    const [url, setUrl] = useState(initUrl ?? location.pathname + location.search);

    const isBuyingOrIsRentingUrl = url && url.startsWith(URLS.buy()) && url.split("/").length > 2;

    const refreshPage = (newUrl?: string) =>
        setTimeout(() => {
            window.location.href = newUrl ?? url;
        }, 50);

    return { isBuyingOrIsRentingUrl, refreshPage, setUrl, url };
}
