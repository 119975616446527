import React, { lazy } from "react";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { Button } from "_common/ui/mui/buttons/Button";
import { URLS } from "_configs/URLS";
import i18next from "i18next";
import { fetchSearchProperties, getNewsCoordinatesFromMapCoordinates } from "_common/_utils/searchUtils";
import Suspenser from "_common/loaders/Suspenser";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { usePropertiesStore } from "properties/PropertiesPageContext";

const LazyMapRenderLeaflet = lazy(() => import("../maps/MapListingProperties"));

export const PropertiesViewMap = () => {
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();

    return (
        <div className={"relative flex flex-row flex-1"}>
            <Suspenser noSpinner noText fallback={<div>MAP</div>}>
                <LazyMapRenderLeaflet listStore={listStore} />
                {geoZonesStore.geoZone && (
                    <div
                        className={
                            "absolute top-0 left-0 z-[500] opacity-[95%] underline m-2.5 md:translate-x-2/4 md:left-[unset] md:top-[unset] md:right-2/4 md:bottom-0"
                        }
                    >
                        <Button
                            onClick={async () => {
                                geoZonesStore.resetGeoZone();
                                propertiesStore.addressParams.city = "";
                                propertiesStore.addressParams.address = "";
                                propertiesStore.addressParams.region = "";
                                history.push(
                                    URLS.buy(
                                        i18next.language,
                                        getNewsCoordinatesFromMapCoordinates(
                                            propertiesStore.searchParams.mapCoordinates,
                                        ),
                                    ) + location.search,
                                );
                                fetchSearchProperties(propertiesStore, listStore, history);
                            }}
                            variant={"contained"}
                            color={"primary"}
                            className={"!underline"}
                        >
                            {t("zone.reset")}
                        </Button>
                    </div>
                )}
            </Suspenser>
        </div>
    );
};
