import React from "react";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import units from "../../assets/images/icons/units.svg";
import propertyType from "../../assets/images/icons/propertyType.svg";
import bed from "../../assets/images/icons/bed.svg";

type Props = {
    property: TPropertyListingMdl;
};

export function PropertyDetails(props: Props) {
    const { t } = useTranslation();

    const formatBedroomNumber = (min: number, max: number) => {
        return min === max
            ? `${min} ${t("propertyPage.bedUnits")}`
            : `${Array.from({ length: max - min + 1 }, (_, i) => i + min)
                  .join(", ")
                  .replace(/, ([^,]*)$/, " & $1")} ${t("propertyPage.bedUnits")}`;
    };

    return (
        <div className={"flex flex-col gap-5 ml-2.5"}>
            <div className={"flex items-center gap-5"}>
                <LazyLoadImage src={units} />
                {props.property.nbOfUnitsFiltered} {t("propertyPage.unitsAvailable")} / {props.property.nbOfUnits}{" "}
                {t("propertyPage.totalUnits")}
            </div>

            <div className={"flex items-center gap-6 "}>
                <LazyLoadImage src={propertyType} />
                <span>
                    {t("propertyPage.propertyType")}
                    <span className={"capitalize"}>{props.property.type}</span>
                </span>
            </div>
            {!!props.property.bedrooms.min && props.property.bedrooms.max && (
                <div className={"flex items-center gap-6"}>
                    <LazyLoadImage src={bed} />
                    {formatBedroomNumber(props.property.bedrooms.min, props.property.bedrooms.max)}
                </div>
            )}
        </div>
    );
}
