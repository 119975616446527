import { getI18nExpByLang } from "_common/_utils/pageUtils";
import i18next from "i18next";
import { CURRENCY_OPTIONS, PRICE_CONVERSION } from "_common/currency/CurrencyTypes";

export function scientistNumber(value: number, digits: number, alreadyDivided = false) {
    let num = value;
    if (!alreadyDivided) num = num / 100;
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value;
        });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
export function formatPrice(
    price: number,
    alreadyDivided = false,
    asterix = false,
    currency = "USD",
    scientistDisplay = false,
) {
    if (!price) return "0";
    let displayPrice = Math.round(price);
    displayPrice = alreadyDivided ? displayPrice : displayPrice / 100;
    const language = getI18nExpByLang(i18next.language, `currency.lang.${i18next.language}`);
    const formatter = new Intl.NumberFormat(language, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: price % 1 === 0 ? 0 : 2,
        maximumFractionDigits: 2,
    });
    const currencySymbol = formatter.formatToParts(0).find((part) => part.type === "currency")?.value || currency;
    let formatPrice = formatter.format(displayPrice).replace("$", " $ ").replace("€", " € ").replace("CA", " CA ");
    if (scientistDisplay) {
        const scientistPrice = scientistNumber(displayPrice, 2, true);
        formatPrice = currencySymbol + "  " + scientistPrice;
    }
    if (i18next.language !== "fr") {
        formatPrice = formatPrice.replaceAll(",", " ").replace("€", "EUR");
    }
    if (asterix) formatPrice += "*";
    return formatPrice;
}

export function formatPesosPrice(price: number, alreadyDivided = false, asterix = false) {
    if (!price) return "0";
    let displayPrice = Math.round(price);
    displayPrice = alreadyDivided ? displayPrice : displayPrice / 100;

    const language = getI18nExpByLang(i18next.language, `currency.lang.${i18next.language}`);
    const formatter = new Intl.NumberFormat(language, {
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: price % 1 === 0 ? 0 : 2,
        maximumFractionDigits: 2,
    });
    let formatPrice = formatter.format(displayPrice);
    if (language !== "fr") {
        formatPrice = formatter.format(displayPrice).replaceAll(",", " ").replace("$", " $ ");
    }
    if (asterix) formatPrice += "*";
    return formatPrice;
}

export function convertPrices(value: number, ratio: PRICE_CONVERSION, round?: number) {
    const roundedValue = round ?? 100;
    return Math.round(value * ratio * roundedValue) / roundedValue;
}

export function getConversionRate(from: CURRENCY_OPTIONS, to: CURRENCY_OPTIONS): number {
    switch (from) {
        case CURRENCY_OPTIONS.MXN:
            switch (to) {
                case CURRENCY_OPTIONS.USD:
                    return PRICE_CONVERSION.PESOS_TO_USD;
                case CURRENCY_OPTIONS.EUR:
                    return PRICE_CONVERSION.PESOS_TO_EUR;
                case CURRENCY_OPTIONS.CAD:
                    return PRICE_CONVERSION.PESOS_TO_CAD;
            }
            break;
        case CURRENCY_OPTIONS.USD:
            switch (to) {
                case CURRENCY_OPTIONS.MXN:
                    return PRICE_CONVERSION.USD_TO_PESOS;
                case CURRENCY_OPTIONS.EUR:
                    return PRICE_CONVERSION.USD_TO_EUR;
                case CURRENCY_OPTIONS.CAD:
                    return PRICE_CONVERSION.USD_TO_CAD;
            }
            break;
        case CURRENCY_OPTIONS.EUR:
            switch (to) {
                case CURRENCY_OPTIONS.USD:
                    return PRICE_CONVERSION.EUR_TO_USD;
                case CURRENCY_OPTIONS.MXN:
                    return PRICE_CONVERSION.EUR_TO_PESOS;
                case CURRENCY_OPTIONS.CAD:
                    return PRICE_CONVERSION.EUR_TO_CAD;
            }
            break;
        case CURRENCY_OPTIONS.CAD:
            switch (to) {
                case CURRENCY_OPTIONS.USD:
                    return PRICE_CONVERSION.CAD_TO_USD;
                case CURRENCY_OPTIONS.MXN:
                    return PRICE_CONVERSION.CAD_TO_PESOS;
                case CURRENCY_OPTIONS.EUR:
                    return PRICE_CONVERSION.CAD_TO_EUR;
            }
            break;
        default:
            return 1;
    }
    return 1;
}

export function calculateUSDPriceWithCurrency(value: number, currencyDisplay: CURRENCY_OPTIONS) {
    switch (currencyDisplay) {
        case CURRENCY_OPTIONS.USD:
            return value;
        case CURRENCY_OPTIONS.MXN:
            return convertPrices(value, PRICE_CONVERSION.USD_TO_PESOS);
        case CURRENCY_OPTIONS.CAD:
            return convertPrices(value, PRICE_CONVERSION.USD_TO_CAD);
        case CURRENCY_OPTIONS.EUR:
            return convertPrices(value, PRICE_CONVERSION.USD_TO_EUR);
        default:
            return value;
    }
}

export function convertToUsd(value: number, currency: CURRENCY_OPTIONS, round?: number) {
    const ratio = getConversionRate(currency, CURRENCY_OPTIONS.USD);
    console.log("ratio", ratio);
    return convertPrices(value, ratio, round);
}

export function convertToPesos(value: number, currency: CURRENCY_OPTIONS, round?: number) {
    const ratio = getConversionRate(currency, CURRENCY_OPTIONS.MXN);
    return convertPrices(value, ratio, round);
}

export function numberFormatter(number?: number | string, replaceValue = " ") {
    if (!number) return "";
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, replaceValue);
}
