import React from "react";
import { useFormContext } from "react-hook-form";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { useTranslation } from "react-i18next";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { notifierStore } from "components/misc/contactUs/_stores/notifierStore";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { observer } from "mobx-react";
import { TAskForVisitOrInfoForm } from "properties/propertyPage/PropertyGalleryCta";
import { AC_TAGS } from "users/_models/UserMdl";
import i18next from "i18next";
import { useLocation } from "react-router-dom";
import { useUnitStore } from "units/UnitContext";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { ButtonNotification } from "components/misc/notifications/ButtonNotification";

type Props = {
    resource: RESOURCE;
};

export const PropertyFormBtn = observer((props: Props) => {
    const { pathname } = useLocation();
    const propertyStore = usePropertyStore();
    const unitStore = useUnitStore();
    const { handleSubmit, errors } = useFormContext<TAskForVisitOrInfoForm>();
    const { loading, setPromise } = useLoadingFromPromise();
    const isLoading = loading?.status === "LOADING";
    const { t } = useTranslation();
    const errorMessage = errors.message ? errors.message.message : errors.email ? errors.email.message : "";
    return (
        <>
            {loading?.isSucceeded && <div id={"successRequest"}>{t("contactPage.succeededSend")}</div>}
            <ErrorBlock error={errorMessage ?? loading?.error} />
            <div className="pt-[23px] pb-2.5 flex items-center">
                <ButtonNotification
                    className={"!rounded-3xl !text-sm !font-normal !py-3.5 !px-[38px]"}
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleSubmit<TAskForVisitOrInfoForm>((data) => {
                        if (!isLoading) {
                            setPromise(
                                notifierStore.askForInfo({
                                    ...(data as TAskForVisitOrInfoForm),
                                    propertyId: propertyStore.property._id,
                                    unitId: props.resource === RESOURCE.UNIT_TYPES ? unitStore?.unitType._id : "",
                                    acTags:
                                        props.resource === RESOURCE.UNIT_TYPES ? AC_TAGS.L1_UNIT : AC_TAGS.L2_PROPERTY,
                                    lang: i18next.language,
                                    url: pathname,
                                    unitNumber: props.resource === RESOURCE.UNIT_TYPES ? unitStore?.unitNumber : "",
                                }),
                            );
                        }
                    })}
                >
                    {isLoading ? t("loaders.message") : t("propertyPage.contactForm.submit")}
                </ButtonNotification>
            </div>
            {/*<RecaptchaDisclaimer />*/}
        </>
    );
});
