import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import styles from "./_css/propertiesView.module.css";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { PropertiesViewList } from "properties/PropertiesViewList";
import { usePropertyPageContext } from "properties/PropertyPageContext";
import { scrollTop } from "_common/_utils/ScrollTop";
import { PropertiesViewButtonsMobile } from "properties/PropertiesViewButtonsMobile";
import { MAP_OR_LIST_SELECTED } from "properties/components/BREAKPOINTS_SECTION_CARDS";
import { PropertiesViewMap } from "properties/PropertiesViewMap";

export const PropertiesView = () => {
    const propertiesStore = usePropertiesStore();
    const { mapOrListSelected } = usePropertyPageContext();
    const resultsContainer = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setTimeout(() => {
            if (resultsContainer && resultsContainer?.current?.scrollTop) {
                scrollTop(resultsContainer, resultsContainer.current.scrollTop);
            }
        }, 250);
    }, [mapOrListSelected]);
    return (
        <div
            className={clsx("flex flex-col md:flex-row flex-1 relative", {
                "mb-14": mapOrListSelected === MAP_OR_LIST_SELECTED.LIST,
            })}
            onMouseLeave={() => propertiesStore.setMapPropertySelected(undefined)}
        >
            <div
                ref={resultsContainer}
                className={clsx("flex flex-col px-2", {
                    [styles.resultsMap]: mapOrListSelected === MAP_OR_LIST_SELECTED.MAP,
                    ["w-0 hidden md:w-1/2 md:flex"]: mapOrListSelected === MAP_OR_LIST_SELECTED.MAP,
                    ["w-full h-full overflow-hidden"]: mapOrListSelected === MAP_OR_LIST_SELECTED.LIST,
                })}
            >
                <PropertiesViewList />
            </div>
            {mapOrListSelected === MAP_OR_LIST_SELECTED.MAP && <PropertiesViewMap />}
            <PropertiesViewButtonsMobile />
        </div>
    );
};
