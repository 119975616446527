import React from "react";
import { TPropertyConstructProgress } from "properties/_models/PropertyConstructProgressMdl";
import styles from "./_css/PropertyGalleyConstructProgress.module.css";
import { VideoComponent } from "components/base/video/VideoComponent";
import dayjs from "dayjs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { TPhase } from "properties/_models/PropertyMdl";
import { PhaseCollapsible } from "properties/gallery/PhaseCollapsible";
import { GALLERY_TABS } from "properties/gallery/PropertyOrUnitGalleryPage";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";

type Props = {
    constructProgress: TPropertyConstructProgress[];
    propertyPhases?: TPhase[];
};

export function PropertyGalleyConstructProgress(props: Props) {
    const propertyStore = usePropertyStore();
    return (
        <div className={styles.container}>
            {props.constructProgress
                .sort((a, b) => (a.date && b.date && dayjs(a.date).diff(dayjs(b.date)) > 0 ? 1 : -1))
                .map((progress) => {
                    const correspondingPhase = props.propertyPhases?.find(
                        (phase) => phase.deliveryDate && dayjs(progress.date).isBefore(dayjs(phase.deliveryDate)),
                    );

                    return (
                        <div key={progress._id}>
                            <div
                                className={
                                    "py-1 md:py-5 text-sm items-center text-center text-[var(--color-primary)] bg-[var(--color-secondary-bg)]"
                                }
                            >
                                {dayjs(progress.date).format("MMMM YYYY")}
                            </div>
                            <PhaseCollapsible correspondingPhase={correspondingPhase}>
                                {progress?.videos?.length > 0 && (
                                    <div>
                                        {progress.videos.map((video) => {
                                            return (
                                                <div
                                                    key={video.url}
                                                    className={"p-2.5 cursor-pointer"}
                                                    onClick={() =>
                                                        propertyStore.openGallery(0, GALLERY_TABS.CONSTRUCT_PROGRESS)
                                                    }
                                                >
                                                    <VideoComponent url={video.url} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                                {progress?.images?.length > 0 && (
                                    <div className={"flex flex-row flex-wrap justify-between"}>
                                        {progress.images.map((image) => {
                                            return (
                                                <div key={image._id} className={"w-[49%] py-2.5"}>
                                                    <LazyLoadImage
                                                        className={"cursor-pointer"}
                                                        src={image.url}
                                                        alt={image?.alt}
                                                        onClick={() =>
                                                            propertyStore.openGallery(
                                                                0,
                                                                GALLERY_TABS.CONSTRUCT_PROGRESS,
                                                            )
                                                        }
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </PhaseCollapsible>
                        </div>
                    );
                })}
        </div>
    );
}
