import { PROPERTY_PURPOSE, PROPERTY_TYPE, TAddressMdl, TRangeNumber } from "properties/_models/PropertyMdl";
import { TMeta } from "pages/_models/PageMdl";
import { Dayjs } from "dayjs";
import { TMapLocation } from "maps/Map";
import { TLang } from "_configs/sharedConfig";
import { TImage } from "_common/types/baseTypes";

export enum GEO_ZONE_TYPE {
    PROVINCE = "PROVINCE",
    CITY = "CITY",
    NEIGHBORHOOD = "NEIGHBORHOOD",
}

export type TFaq = {
    question: string;
    answer: string;
};

export type TGeoZoneLocalize = {
    [lang in TLang]: TLocalizedGeoZoneLocalize;
};

export type TLocalizedGeoZoneLocalize = {
    [key in "beachfront" | "luxury" | PROPERTY_PURPOSE.BUY]: {
        [propertyType in PROPERTY_TYPE]: {
            meta: TMeta;
            text: string;
            title: string;
            faq: TFaq[];
        };
    } & {
        meta: TMeta;
        text: string;
        title: string;
        faq: TFaq[];
    };
};

export type TPropertyData = {
    publishedProperties: number;
    prices: TRangeNumber;
};

export type TPropertiesData = {
    [type in PROPERTY_TYPE]: TPropertyData;
} & {
    generic: TPropertyData;
    beachfront: TPropertyData;
    luxury: TPropertyData;
};

export type TGeoZoneBaseMdl = {
    osmId?: string;
    name: string;
    slug: string;
    address: TAddressMdl;
    location: { type: string; coordinates: number[] };
    zoneLocations: TMapLocation[];
    type?: GEO_ZONE_TYPE;
    position?: number;
    properties?: TPropertiesData;
    image: TImage;

    linkedGeoZone: string;
    isLinkedGeoZone: boolean;
    isCommunity: boolean;

    updatedAt?: Dayjs;
    createdAt?: Dayjs;
};

export interface TGeoZoneMdl extends TGeoZoneBaseMdl {
    _id: string;
    localized: TGeoZoneLocalize;
}

export interface TEditGeoZoneMdl extends TGeoZoneBaseMdl {
    _id?: string;
    localized: TGeoZoneLocalize;
}

const createDefaultLocalizedObject = (): TLocalizedGeoZoneLocalize => {
    const defaultMeta = { title: "", description: "", faq: [] };
    const defaultText = { meta: defaultMeta, text: "", title: "", faq: [] };

    const createDefaultPropertyTypeObject = (): { [propertyType in PROPERTY_TYPE]: typeof defaultText } => {
        const propertyTypeObject = {} as { [propertyType in PROPERTY_TYPE]: typeof defaultText };
        for (const propertyType of Object.keys(PROPERTY_TYPE) as Array<keyof typeof PROPERTY_TYPE>) {
            propertyTypeObject[propertyType] = { ...defaultText };
        }
        return propertyTypeObject;
    };

    return {
        BUY: {
            ...createDefaultPropertyTypeObject(),
            ...defaultText,
        },
        beachfront: {
            ...createDefaultPropertyTypeObject(),
            ...defaultText,
        },
        luxury: {
            ...createDefaultPropertyTypeObject(),
            ...defaultText,
        },
    };
};

const propertiesDatas: Partial<TPropertiesData> = { generic: { publishedProperties: 0, prices: { min: 0, max: 0 } } };
for (const type of Object.keys(PROPERTY_TYPE)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (!propertiesDatas[type]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        propertiesDatas[type] = { publishedProperties: 0, prices: { min: 0, max: 0 } };
    }
}

export const defaultGeoZone: Partial<TGeoZoneMdl> = {
    osmId: "",
    name: "",
    address: {
        city: "",
        province: "",
        neighbourhood: "",
        provinceLong: "",
        provinceShort: "",
    },
    location: {
        type: "Point",
        coordinates: [],
    },
    properties: propertiesDatas as TPropertiesData,
    zoneLocations: [],
    type: GEO_ZONE_TYPE.CITY,
    localized: {
        en: createDefaultLocalizedObject(),
        fr: createDefaultLocalizedObject(),
        es: createDefaultLocalizedObject(),
        zh: createDefaultLocalizedObject(),
    },
    isLinkedGeoZone: false,
};
