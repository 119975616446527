import React from "react";
import { TAmenity, TFeature, TFees } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import checkCircle from "../../assets/images/icons/checkCircle.svg";

type Props = {
    amenities: TAmenity;
    features: TFeature;
    fees: TFees;
};

export function PropertyAmenities(props: Props) {
    const { t } = useTranslation();
    return (
        <div className={"flex flex-col gap-3"}>
            <div className="border rounded-lg pl-[23px] py-5 border-[var(--color-separator)]">
                <h3 className={"text-[var(--color-primary)] mb-8"}>{t("unit.featuresTitle")}</h3>
                {!!props.features && (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[26px] gap-x-4">
                        {Object.keys(props.features).map((key, idx) => {
                            if (!props.features[key]) return null;
                            return (
                                <div key={idx} className={"flex items-center text-sm"}>
                                    <div>
                                        <LazyLoadImage src={checkCircle} alt="check icon" />
                                    </div>
                                    <div className="ml-3">{t(`property.features.${key}`)}</div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>

            {!!props.amenities && (
                <div className="border rounded-lg pl-[23px] py-5 space-y-1.5 border-[var(--color-separator)]">
                    <h3 className={"text-[var(--color-primary)] mb-8"}>{t("propertyPage.section.amenities")}</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[26px] gap-x-4">
                        {Object.keys(props.amenities).map((key, idx) => {
                            if (!props.amenities[key]) return null;
                            return (
                                <div key={idx} className={"flex items-center text-sm"}>
                                    <div>
                                        <LazyLoadImage src={checkCircle} alt="check icon" />
                                    </div>
                                    <div className="ml-3">{t(`property.amenities.${key}`)}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}

            {!!props.fees && (
                <div className="border rounded-lg pl-[23px] py-5 space-y-1.5 border-[var(--color-separator)] text-sm">
                    <div className={"flex gap-1"}>
                        <div className={"font-bold"}>{t("propertyPage.fees")}</div>
                        <div>
                            {t(`propertyPage.feesTypes.${props.fees.type}`, {
                                count: props.fees.value,
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
