import React, { PropsWithChildren } from "react";
import { propertiesBuyingStore } from "properties/_stores/propertiesBuyingStore";
import { PROPERTY_PURPOSE, PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { Link, useHistory } from "react-router-dom";
import { TFilter } from "admin/_common/filters/TFilter";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { fetchSearchProperties } from "_common/_utils/searchUtils";
import { getUrlLinkGeoZone } from "_common/_utils/geoZoneUtils";

type Props = {
    to: string;
    propertyType?: PROPERTY_TYPE | "beachfront" | "luxury";
    removeGeoZone?: boolean;
};

export function NavSrpsLink({ removeGeoZone, propertyType, to, ...props }: PropsWithChildren<Props>) {
    const history = useHistory();
    const propertiesStore = propertiesBuyingStore;
    const url = removeGeoZone ? to : getUrlLinkGeoZone(geoZonesStore.geoZone, propertyType);

    const handleClick = async () => {
        const listStore =
            propertiesStore.listsStores[PROPERTY_PURPOSE.BUY] ?? propertiesStore.getListStore(PROPERTY_PURPOSE.BUY);
        listStore.removeFilterByFilterId("features.beachfront");
        listStore.removeFilterByFilterId("features.luxury");
        listStore.removeFilterByFilterId("type");
        if (removeGeoZone) {
            listStore.removeFilterByFilterId("location");
            geoZonesStore.resetGeoZone();
        }
        if (propertyType) {
            const filterType: TFilter = {
                id: "type",
                value: [propertyType],
                type: TFilterType.IN,
            };
            const beachFrontFilter = {
                id: "features.beachfront",
                type: TFilterType.BOOLEAN,
                value: true,
            };
            const luxuryFilter = {
                id: "features.luxury",
                type: TFilterType.BOOLEAN,
                value: true,
            };
            listStore.updateFilter(
                propertyType === "beachfront"
                    ? beachFrontFilter
                    : propertyType === "luxury"
                    ? luxuryFilter
                    : filterType,
            );
        }
        const geozone = await geoZonesStore.setGeoZoneFromUrl(url);
        if (geozone) {
            propertiesStore.setLocationFromSearch(geozone);
        }

        fetchSearchProperties(propertiesStore, listStore, history);
    };
    return (
        <Link onClick={handleClick} to={url}>
            {props.children}
        </Link>
    );
}
