import React from "react";
import { observer } from "mobx-react";
import { PropertyCardListing } from "properties/propertyCard/PropertyCardListing";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { useSearchPageParams } from "_common/_utils/hookUtils";

export const PropertiesViewListItems = observer(() => {
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);
    const { isLuxury } = useSearchPageParams();

    return (
        <>
            {listStore.paginatedItems.map((property) => {
                if (!property) return null;
                return (
                    <PropertyCardListing
                        key={property._id}
                        property={property}
                        onMouseEnter={() => {
                            propertiesStore.setMapPropertySelected(property?._id);
                        }}
                        onMouseLeave={() => {
                            propertiesStore.setMapPropertySelected.cancel();
                            propertiesStore.setMapPropertySelected(undefined);
                        }}
                        isLuxury={isLuxury}
                    />
                );
            })}
        </>
    );
});
