import React, { useState } from "react";
import { TCardProperty } from "properties/_models/PropertyMdl";
import { CardGalleryLabelType } from "properties/propertyCard/components/PropertyCardComponents";
import { useTranslation } from "react-i18next";
import { PropertyCardFavoriteBtn } from "properties/propertyCard/components/PropertyCardFavoriteBtn";
import { CardGalleryNew } from "properties/propertyCard/components/CardGalleryNew";
import { NoSsr } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import clsx from "clsx";
import { Helmet } from "react-helmet-async";
import { cn } from "_configs/twconfig";

type Props = {
    property: TCardProperty;
    isLuxury?: boolean;
};

export function PropertyCardGallery({ property, isLuxury }: Props) {
    const { t } = useTranslation();
    const [hovered, setHovered] = useState(false);
    return (
        <span onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <Helmet>
                <link rel="preload" as="image" href={property.photos?.[0]?.thumbnail} />
            </Helmet>
            <LazyLoadImage
                src={property.photos?.[0]?.thumbnail}
                alt={"property image"}
                title={`${property.localized.title} photo 1`}
                className={cn(
                    "absolute top-0 left-0 w-full h-full transition-[opacity] duration-[1s] ease-[ease-in-out] opacity-100",
                    { "opacity-0": hovered },
                )}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `https://placehold.co/300x200?text=Zisla.com`;
                }}
            />
            <LazyLoadImage
                src={property.photos?.[1]?.thumbnail}
                alt={"property image"}
                title={`${property.localized.title} photo 2`}
                className={cn(
                    "absolute top-0 left-0 w-full h-full transition-[opacity] duration-[1s] ease-[ease-in-out] opacity-0",
                    { "opacity-100": hovered },
                )}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `https://placehold.co/300x200?text=Zisla.com`;
                }}
            />
            <CardGalleryLabelType
                type={t("property.types." + property.type)}
                className={clsx({ "-rotate-90 w-[80px] h-[30px] pr-5 pl-1 -left-2 top-6": isLuxury })}
            />
            <NoSsr>
                <CardGalleryNew date={property.firstDayOnSite} />
            </NoSsr>
            <NoSsr>
                <PropertyCardFavoriteBtn property={property} />
            </NoSsr>
        </span>
    );
}
