import React, { PropsWithChildren, useState } from "react";
import { Collapse } from "@material-ui/core";
import clsx from "clsx";
import { KeyboardArrowDown } from "@material-ui/icons";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = PropsWithChildren<{
    icon?: string;
    title: string;
    heightOfCollapsable: number;
    className?: string;
    titleClassName?: string;
    keyboardArrowDownSize?: "small" | "large";
    iconClassName?: string;
}>;

export function CollapseSection({
    icon,
    title,
    children,
    heightOfCollapsable,
    className,
    titleClassName,
    keyboardArrowDownSize,
    iconClassName,
}: Props) {
    const [isCollapse, setIsCollapse] = useState(true);

    return (
        <div>
            <div
                className={clsx(
                    "text-sm flex flex-row items-center justify-between cursor_pointer py-2 px-4",
                    className,
                )}
                onClick={() => setIsCollapse(!isCollapse)}
            >
                <div className="flex items-center">
                    {icon && <LazyLoadImage src={icon} className={clsx("mr-4 w-[27px]", iconClassName)} />}
                    <div className={clsx("m-0 text_xsmall fontWeight_bold", titleClassName)}>{title}</div>
                </div>
                <div
                    className={clsx("flex items-center justify-center transition-all duration-[0.3s]", {
                        ["rotate-180"]: !isCollapse,
                    })}
                >
                    <KeyboardArrowDown fontSize={keyboardArrowDownSize} />
                </div>
            </div>
            <Collapse in={!isCollapse} collapsedHeight={heightOfCollapsable}>
                {children}
            </Collapse>
        </div>
    );
}
