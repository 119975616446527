import React from "react";
import { TPhase } from "properties/_models/PropertyMdl";
import { CollapseSection } from "_common/ui/components/CollapseSection";
import dayjs from "dayjs";
import calendar from "../../assets/images/icons/calendar.svg";
import { useTranslation } from "react-i18next";

type Props = {
    correspondingPhase: TPhase | undefined;
    children: React.ReactNode;
};

export function PhaseCollapsible({ correspondingPhase, children }: Props) {
    const { t } = useTranslation();
    return (
        <CollapseSection
            title={`${t("propertyPage.phases.phase")} ${correspondingPhase?.numberPhase} - ${t(
                "propertyPage.phases.deliveryIn",
            )} ${dayjs(correspondingPhase?.deliveryDate).format("MMMM YYYY")}`}
            heightOfCollapsable={0}
            icon={calendar}
            className={"bg-[var(--color-primary-background)] h-[27px]"}
            titleClassName={"text-xs font-normal"}
            iconClassName={"!w-3.5 !mr-2"}
        >
            {children}
        </CollapseSection>
    );
}
