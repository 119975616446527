import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i from "../../../assets/images/icons/i.svg";
import { PropertyCardPriceValue } from "./PropertyCardPriceValue";
import { PriceInfoCard } from "properties/PriceInfoCard";

type Props = {
    priceMin: number;
    priceInPesosMin: number;
    isInPesosProperty: boolean;
    isPriceNotDisplayed: boolean;
};

export function PropertyCardPrice({ priceMin, priceInPesosMin, isInPesosProperty, isPriceNotDisplayed }: Props) {
    const { t } = useTranslation();
    const [showPriceInfoCard, setShowPriceInfoCard] = useState(false);
    return (
        <div className={"flex flex-row items-center gap-1"}>
            <div className={"flex items-center justify-center"}>
                <img
                    src={i}
                    alt={"informations"}
                    onMouseEnter={() => setShowPriceInfoCard(true)}
                    onMouseLeave={() => setShowPriceInfoCard(false)}
                />
            </div>
            {isPriceNotDisplayed ? (
                <div>{t("propertiesPage.card.priceOnRequest")}</div>
            ) : (
                <PropertyCardPriceValue
                    isInPesosProperty={isInPesosProperty}
                    priceMin={priceMin}
                    priceInPesosMin={priceInPesosMin}
                />
            )}
            {showPriceInfoCard && (
                <div className="absolute z-50 translate-y-16 translate-x-5 ">
                    <PriceInfoCard />
                </div>
            )}
        </div>
    );
}
