import React from "react";
import { Meta } from "_common/_utils/Meta";
import i18next from "i18next";
import { URLS } from "_configs/URLS";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import { HIDDEN_PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { PARAMS_PAGE_PREFIX } from "_common/_utils/searchUtils";

export function PropertiesMeta() {
    const {
        isAtLeastProvincePage,
        isAtLeastCityPage,
        isAtLeastNeighborhoodPage,
        propertyType,
        beachFrontOrLuxury,
        page,
    } = useSearchPageParams();
    const propertiesStore = usePropertiesStore();

    let propertyKey = "";
    const types = getI18nExpByLang(i18next.language, "property.types");
    for (const key of Object.keys(types)) {
        const tmpLang = i18next.language === "zh" ? "en" : i18next.language;
        if (
            getI18nExpByLang(tmpLang, "property.types." + key)
                .replace(/ /g, "_")
                .toLowerCase() === propertyType
        ) {
            propertyKey = (key as unknown) as string;
        }
    }
    let url = isAtLeastProvincePage
        ? URLS.buy(
              i18next.language,
              reformatStringForUrls(
                  isAtLeastProvincePage
                      ? propertiesStore.addressParams.regionLong
                      : propertiesStore.addressParams.region,
              ),
              isAtLeastCityPage ? reformatStringForUrls(propertiesStore.addressParams.city) : undefined,
              propertiesStore.addressParams?.neighbourhood
                  ? reformatStringForUrls(propertiesStore.addressParams?.neighbourhood)
                  : propertyType ?? beachFrontOrLuxury ?? undefined,
              propertiesStore.addressParams?.neighbourhood ? propertyType ?? propertyType ?? undefined : undefined,
              propertiesStore.addressParams?.neighbourhood && propertyType && beachFrontOrLuxury
                  ? beachFrontOrLuxury
                  : undefined,
          )
        : URLS.buy(
              i18next.language,
              propertyType ?? beachFrontOrLuxury,
              propertyType ? beachFrontOrLuxury : undefined,
          ).toLowerCase();
    const alternateUrls = [];
    if (page && page > 1) url += "/" + PARAMS_PAGE_PREFIX + page;
    for (const lang of Object.keys(sharedConfig.languages)) {
        let route = "";
        if (isAtLeastProvincePage && propertiesStore.addressParams) {
            route =
                sharedConfig.appUrl +
                "/" +
                lang +
                "/" +
                getI18nExpByLang(lang, "routes.buy") +
                "/" +
                reformatStringForUrls(
                    !isAtLeastCityPage
                        ? propertiesStore.addressParams.regionLong
                        : propertiesStore.addressParams.region,
                ) +
                "/" +
                (isAtLeastCityPage ? `${reformatStringForUrls(propertiesStore.addressParams.city)}/` : "") +
                (isAtLeastNeighborhoodPage
                    ? `${reformatStringForUrls(propertiesStore.addressParams.neighbourhood)}/`
                    : "") +
                (propertyKey
                    ? `${getI18nExpByLang(lang === "zh" ? "en" : lang, "property.types." + propertyKey).toLowerCase()}/`
                    : "");
        } else {
            route =
                sharedConfig.appUrl +
                "/" +
                lang +
                "/" +
                getI18nExpByLang(lang, "routes.buy") +
                "/" +
                (propertyType
                    ? getI18nExpByLang(lang === "zh" ? "en" : lang, "property.types." + propertyKey).toLowerCase()
                    : "");
        }

        alternateUrls.push({
            url: route,
            lang: lang as TLang,
        });
    }

    return (
        <Meta
            title={undefined}
            description={undefined}
            url={url}
            alternateUrls={alternateUrls}
            noIndex={HIDDEN_PROPERTY_TYPE.includes(propertyKey)}
        />
    );
}
