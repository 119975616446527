import { CommunitiesBreadcrumb } from "communities/CommunitiesBreadcrumb";
import { Container } from "@material-ui/core";
import { CommunitiesImageSlider } from "communities/CommunitiesImageSlider";
import { CommunitiesInformationSection } from "communities/CommunitiesInformationSection";
import { Button } from "_common/ui/mui/buttons/Button";
import React, { lazy, Suspense, useMemo, useState } from "react";
import { TCommunitiesListingMdl } from "admin/communities/_models/CommunitiesMdl";
import { CommunityStore } from "communities/_stores/communityStore";
import { CommunityContext } from "communities/CommunityContext";
import { CommunitiesCardSliderContainer } from "communities/components/CommunitiesCardSliderContainer";
import { Loader } from "_common/loaders/Loader";
import { CommunitiesTitle } from "communities/components/CommunitiesTitle";
import { CommunitiesFeaturesAndAmenties } from "communities/components/CommunitiesFeaturesAndAmenties";
import { CommunitiesUsefulFacts } from "communities/components/CommunitiesUsefulFacts";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";
import { CommunitiesDiscover } from "communities/components/CommunitiesDiscover";
import { useTranslation } from "react-i18next";
import { CommunitiesContactFormDialog } from "communities/components/CommunitiesContactFormDialog";
import { Meta } from "_common/_utils/Meta";

const CommunitiesActivitiesMap = lazy(() => import("./components/CommunitiesActivitiesMap"));

type Props = {
    community: TCommunitiesListingMdl;
};

export function CommunityView({ community }: Props) {
    const communityStore = useMemo(() => new CommunityStore(community), [community]);
    const [contactFormVisible, setContactFormVisible] = useState(false);
    const { t } = useTranslation();
    const isOptions =
        (communityStore.community?.amenities && !!Object.values(communityStore.community.amenities).length) ||
        (communityStore.community?.features && !!Object.values(communityStore.community?.features).length);
    return (
        <CommunityContext communityStore={communityStore}>
            <Meta
                title={community.localized?.title}
                description={community.localized?.description}
                image={community.localized?.meta?.image}
            />
            <Container className=" !max-w-[1260px] mb-[25px] !px-2.5">
                <CommunitiesBreadcrumb />
            </Container>

            <Container className=" !max-w-[1260px] mb-1.5 md:mb-[27px] !px-2.5">
                <CommunitiesImageSlider />
            </Container>

            <Container className="!max-w-[1260px] mb-[36px] !px-2.5">
                <CommunitiesInformationSection />
            </Container>

            <Container className=" !max-w-[1260px] mb-[33px] md:mb-[113px] !px-2.5">
                <CommunitiesContactFormDialog
                    isOpen={contactFormVisible}
                    setIsOpen={() => setContactFormVisible(false)}
                />
                <Button
                    variant={"contained"}
                    onClick={() => setContactFormVisible(true)}
                    className={"!px-[24px] !py-[12px]"}
                >
                    {t("communitiesPage.requestInfo")}
                </Button>
            </Container>

            <Container className=" !max-w-[1260px] mb-[25px] !px-2.5">
                <CommunitiesCardSliderContainer />
            </Container>

            <Container className="!flex md:!justify-center !max-w-[1260px] mb-[51px] md:mb-[67px] !px-2.5">
                <Link to={URLS.buy()}>
                    <Button variant={"outlined"} className={"!py-[22px] !px-[38px]"}>
                        {t("communitiesPage.viewAllProperties")}
                    </Button>
                </Link>
            </Container>

            <Container className=" !max-w-[1260px] mb-[51px] md:mb-[82px] !px-2.5">
                <CommunitiesTitle
                    className={"md:!mb-10"}
                    title={`${t("communitiesPage.discover")} ${community.localized.title} ${t("words.in")} ${
                        community.geoZone.address.city
                    }`}
                    delimiter={2}
                />
                <CommunitiesDiscover videoLink={community.videoLink} />
            </Container>

            <Container className=" !max-w-[1260px] mb-[41px] md:mb-[71px] !px-2.5">
                <CommunitiesTitle
                    title={`${t("communitiesPage.map")}`}
                    delimiter={1}
                    className={"!flex-row gap-2.5 md:!mb-10"}
                />
                <Suspense fallback={<Loader />}>
                    <CommunitiesActivitiesMap />
                </Suspense>
            </Container>

            {isOptions && (
                <Container className=" !max-w-[1260px] mb-[51px] md:mb-[81px] !px-2.5">
                    <CommunitiesFeaturesAndAmenties />
                </Container>
            )}

            {!!communityStore.community?.facts.length && (
                <Container className=" !max-w-[1260px] mb-[51px] !px-2.5">
                    <CommunitiesUsefulFacts />
                </Container>
            )}
        </CommunityContext>
    );
}
