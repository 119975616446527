import React from "react";
import { CollapseSection } from "_common/ui/components/CollapseSection";
import { TPhase, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import propertyDescription from "../../assets/images/icons/propertyDescription.svg";
import amenities from "../../assets/images/icons/amenities.svg";
import history from "../../assets/images/icons/history.svg";
import gatedCommunity from "../../assets/images/icons/gatedCommunity.svg";
import payment from "../../assets/images/icons/payment.svg";
import progress from "../../assets/images/icons/progress.svg";
import propertyPhases from "../../assets/images/icons/propertyPhases.svg";
import { useTranslation } from "react-i18next";
import { Separator } from "_common/ui/components/Separator";
import { PropertyDescription } from "properties/propertyPage/PropertyDescription";
import { PropertyPhases } from "properties/propertyPage/PropertyPhases";
import { PHASE_STATUS } from "admin/phases/_models/PhasesMdl";
import dayjs from "dayjs";
import { PropertyAmenities } from "properties/propertyPage/PropertyAmenities";
import PropertyOrUnitFinancing from "properties/propertyPage/PropertyOrUnitFinancing";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { PropertyDevelopment } from "properties/propertyPage/PropertyDevelopment";

type Props = {
    property: TPropertyListingMdl;
};

export function PropertyCollapsible(props: Props) {
    const { t } = useTranslation();
    const propertyStore = usePropertyStore();

    // temporary data
    const propertyPhase: TPhase[] = [
        {
            numberPhase: 1,
            status: PHASE_STATUS.READY_TO_MOVE_IN,
            monthsNumber: 6,
            deliveryDate: dayjs("2024-08-15T05:00:00.000Z"),
        },
        {
            numberPhase: 2,
            status: PHASE_STATUS.UNDER_CONSTRUCTION,
            monthsNumber: 6,
            deliveryDate: dayjs("2025-02-28T05:00:00.000Z"),
        },
    ];

    const propertySections = [
        {
            title: `${t("propertyPage.section.description")}`,
            icon: propertyDescription,
            component: (property: TPropertyListingMdl) => {
                if (!property.localized.description) return null;
                return <PropertyDescription description={props.property.localized.description} />;
            },
            key: "props.property.localized.description",
        },
        {
            title: `${t("propertyPage.section.phases")}`,
            icon: propertyPhases,
            component: () => <PropertyPhases phases={propertyPhase} />,
        },
        {
            title: `${t("propertyPage.section.amenities")}`,
            icon: amenities,
            component: (property: TPropertyListingMdl) => {
                if (!property.amenities && !property.features && !property.fees) return null;
                return (
                    <PropertyAmenities
                        amenities={props.property.amenities}
                        features={props.property.features}
                        fees={props.property.fees}
                    />
                );
            },
        },
        {
            title: `${t("propertyPage.section.history")}`,
            icon: history,
            component: () => <div>HISTORIC TABLEAU</div>,
        },
        {
            title: `${t("propertyPage.section.gatedCommunity")}`,
            icon: gatedCommunity,
            component: () => <div>GATED COMMUNITIES OF PROPERTY</div>,
        },
        {
            title: `${t("propertyPage.section.payment")}`,
            icon: payment,
            component: (property: TPropertyListingMdl) => {
                if (!property.financing || property.financing.length === 0) return null;
                return <PropertyOrUnitFinancing propertyOrUnit={props.property} />;
            },
        },
        {
            title: `${t("propertyPage.section.development")}`,
            icon: progress,
            component: (property: TPropertyListingMdl) => {
                if (!property.floorPlans && !property.constructProgress && !property.finishings) return null;
                return (
                    <PropertyDevelopment
                        property={props.property}
                        propertyStore={propertyStore}
                        propertyPhases={propertyPhase}
                    />
                );
            },
        },
    ];

    return (
        <div className={"mx-2.5 md:mx-0"}>
            <Separator />
            {propertySections.map((section, index) => {
                if (!section.component(props.property)) return null;
                return (
                    <div key={index}>
                        <CollapseSection
                            key={index}
                            icon={section.icon}
                            title={section.title}
                            heightOfCollapsable={0}
                            titleClassName={"text-base font-semibold"}
                            className={"pl-0 pr-0 py-4"}
                            keyboardArrowDownSize={"small"}
                        >
                            <div className={"text-base pb-[18px]"}>{section.component(props.property)}</div>
                        </CollapseSection>
                        <Separator />
                    </div>
                );
            })}
        </div>
    );
}
