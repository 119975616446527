import React, { useEffect, useMemo, useState } from "react";
import { CommunitiesTitle } from "communities/components/CommunitiesTitle";
import { useCommunityStore } from "communities/CommunityContext";
import { useTranslation } from "react-i18next";
import checkCircle from "../../assets/images/icons/checkCircle.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

export function CommunitiesFeaturesAndAmenties() {
    const { t } = useTranslation();
    const { community } = useCommunityStore();
    const [showAll, setShowAll] = useState(false);
    const [itemsToShow, setItemsToShow] = useState(12);

    useEffect(() => {
        const updateItemsToShow = () => {
            if (window.innerWidth >= 768) {
                setItemsToShow(12);
            } else {
                setItemsToShow(24);
            }
        };

        updateItemsToShow();
        window.addEventListener("resize", updateItemsToShow);

        return () => {
            window.removeEventListener("resize", updateItemsToShow);
        };
    }, []);

    const featuresAmenitiesArray = useMemo(
        () => [
            ...(community.features
                ? Object.keys(community.features).map((key) => (
                      <li key={key} className={"flex items-center gap-[13px]"}>
                          <LazyLoadImage src={checkCircle} alt={"check_icon"} />
                          {t(`property.features.${key}`)}
                      </li>
                  ))
                : []),
            ...(community.amenities
                ? Object.keys(community.amenities).map((key) => (
                      <li key={key} className={"flex items-center gap-2"}>
                          <LazyLoadImage src={checkCircle} alt={"check_icon"} />
                          {t(`property.amenities.${key}`)}
                      </li>
                  ))
                : []),
        ],
        [community],
    );

    const displayedFeatures = showAll ? featuresAmenitiesArray : featuresAmenitiesArray.slice(0, itemsToShow);

    return (
        <div className={" flex flex-col p-5 gap-0 md:gap-6 border rounded "}>
            <CommunitiesTitle title={t("communitiesPage.featuresAmenities")} delimiter={2} />
            <ul className="grid grid-cols-1 gap-2 md:grid-cols-2">{displayedFeatures.map((c) => c)}</ul>
            {!showAll && featuresAmenitiesArray.length > itemsToShow && (
                <div className={"text-center cursor-pointer underline"} onClick={() => setShowAll(true)}>
                    {t("communitiesPage.viewMore")}
                </div>
            )}

            {showAll && (
                <div className={"text-center cursor-pointer underline"} onClick={() => setShowAll(false)}>
                    {t("communitiesPage.viewLess")}
                </div>
            )}
        </div>
    );
}
