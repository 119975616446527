import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./_css/propertyOrUnitFinancing.module.css";
import { TFinancing, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import clsx from "clsx";
import { TUnitTypeListingMdl } from "units/_models/UnitTypeMdl";

type Props = {
    propertyOrUnit: TPropertyListingMdl | TUnitTypeListingMdl;
};

export default function PropertyOrUnitFinancing(props: Props) {
    const { t } = useTranslation();
    if (!props.propertyOrUnit.financing || props.propertyOrUnit.financing.length === 0) return null;
    const indexOfLongestFinancingArr = props.propertyOrUnit.financing.reduce(
        (p, c, i, a) => (Object.values(a[p]).length > Object.values(c).length ? p : i),
        0,
    );
    return (
        <div>
            <div className={clsx(styles.innerContainer, "mb-5 border border-[var(--color-separator)] rounded-lg")}>
                <table className={clsx(styles.content)}>
                    <thead className={"bg-[var(--color-secondary-bg)] h-[68px]"}>
                        <tr>
                            <th className={styles.firstColumn} />
                            {props.propertyOrUnit.financing.map((_option, index) => {
                                return (
                                    <th key={index} className={styles.cell}>
                                        {"Option " + (index + 1)}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody className={styles.tbody}>
                        {Object.keys(props.propertyOrUnit.financing[indexOfLongestFinancingArr ?? 0]).map(
                            (optionName) => {
                                if (optionName === "_id") return;
                                return (
                                    <React.Fragment key={optionName}>
                                        <tr className={"border-b border-[var(--color-separator)]"}>
                                            <td className={styles.firstColumn}>
                                                {t(`admin.property.financing.${optionName}`)}
                                            </td>
                                            {props.propertyOrUnit.financing.map((option, index) => {
                                                const value = option?.[optionName as keyof TFinancing]
                                                    ? `${option?.[optionName as keyof TFinancing]} %`
                                                    : "-";
                                                return (
                                                    <td key={index} className={clsx("textAlign_center", styles.cell)}>
                                                        {value}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    </React.Fragment>
                                );
                            },
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
