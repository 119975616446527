import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import { ListStore } from "_common/list/ListStore";
import { PROPERTY_TYPE, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import sharedConfig from "_configs/sharedConfig";
import { capitalize, reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import { PHASE_STATUS } from "admin/phases/_models/PhasesMdl";
import { UNIT_OPTIONS } from "_configs/unitConfig";
import i18next from "i18next";
import { CURRENCY_OPTIONS } from "_common/currency/CurrencyTypes";
import { scientistNumber } from "_common/_utils/currencyUtils";
import dayjs from "dayjs";
import { toJS } from "mobx";
import { OPTION_TYPE } from "_configs/propertyConfig";

export const DEFAULT_INITIAL_FILTERS: TFilter[] = [{ id: "published", type: TFilterType.BOOLEAN, value: true }];

export enum FILTER_TYPE {
    propertyType = "propertyType",
    default = "default",
    price = "price",
    bySquareSurface = "bySquareSurface",
    squareSurface = "squareSurface",
}

export enum PROPERTY_FILTERS {
    SQUARE_SURFACE_MAX = "squareSurface.max",
    SQUARE_SURFACE_MIN = "squareSurface.min",
    BY_SQUARE_SURFACE_MAX = "bySquareSurface.max",
    BY_SQUARE_SURFACE_MIN = "bySquareSurface.min",
    BATHROOMS_MAX = "bathrooms.max",
    BATHROOMS_MIN = "bathrooms.min",
    BEDROOMS_MAX = "bedrooms.max",
    BEDROOMS_MIN = "bedrooms.min",
    PRICE_MAX = "price.max",
    PRICE_MIN = "price.min",
    TYPE = "type",
    LOCATION = "location",
    PURPOSE = "purpose",
    AMENITIES = "amenities",
    FEATURES = "features",
    DELIVERY_TYPE_START = "phase.startDate",
    DELIVERY_TYPE_END = "phase.endDate",
    STATUS = "phase.status",
    UNIT_TYPE = "unitType",
    PUBLISHED = "published",
    VIEWS = "views",
}

const unitOptionKeys = UNIT_OPTIONS.map(
    (option) => `${option.optionType === OPTION_TYPE.VIEWS ? "views" : "features"}.${option.key}`,
);
export const UNIT_FILTERS: string[] = [
    PROPERTY_FILTERS.BATHROOMS_MAX,
    PROPERTY_FILTERS.BATHROOMS_MIN,
    PROPERTY_FILTERS.BEDROOMS_MAX,
    PROPERTY_FILTERS.BEDROOMS_MIN,
    PROPERTY_FILTERS.PRICE_MAX,
    PROPERTY_FILTERS.PRICE_MIN,
    PROPERTY_FILTERS.UNIT_TYPE,
    PROPERTY_FILTERS.SQUARE_SURFACE_MAX,
    PROPERTY_FILTERS.SQUARE_SURFACE_MIN,
    PROPERTY_FILTERS.BY_SQUARE_SURFACE_MAX,
    PROPERTY_FILTERS.BY_SQUARE_SURFACE_MIN,
    PROPERTY_FILTERS.STATUS,
    PROPERTY_FILTERS.DELIVERY_TYPE_START,
    PROPERTY_FILTERS.DELIVERY_TYPE_END,
    "views",
    ...unitOptionKeys,
];

export function getAllTypesInAllLang() {
    const allLang = Object.keys(sharedConfig.languages);
    const allTypes = Object.values(PROPERTY_TYPE);
    const allPropertiesInAllLang: string[] = [];
    allLang.map((lang) =>
        allTypes.map((type) =>
            allPropertiesInAllLang.push(reformatStringForUrls(getI18nExpByLang(lang, `property.types.${type}`))),
        ),
    );
    return allPropertiesInAllLang;
}

export function isPropertyType(search: string) {
    const allPropertiesInAllLang: string[] = getAllTypesInAllLang();
    return allPropertiesInAllLang.includes(search);
}

export function getFiltersValueKey(filters: TFilter[], filterKeys: PROPERTY_FILTERS[]): TFilter[] {
    return filters
        .filter((filter) => filterKeys.some((filterKey) => filter.id.includes(filterKey)))
        .sort((a, b) => a.value - b.value);
}

export function getLabelFilterValue(filter: TFilter, currentCurrency: CURRENCY_OPTIONS) {
    const { id: filterKey, value, id2 } = filter;
    const unit = id2 ?? currentCurrency;
    if (filterKey === "type" || filterKey === "unitType") {
        if (!Array.isArray(toJS(value))) return capitalize(value);
        if (value.length === 1) return capitalize(value?.[0] ?? "");
        return `${value.length} ${getI18nExpByLang(i18next.language, "searchBar.labels.nSelected")}`;
    }
    if (filterKey.startsWith("bedroom")) {
        if (parseInt(value) === 0) return "Studio...";
        return `${value} ${getI18nExpByLang(i18next.language, "searchBar.filter.bedrooms")}...`;
    }
    if (filterKey.startsWith("bathroom")) {
        return `${value} ${getI18nExpByLang(i18next.language, "searchBar.filter.bathrooms")}...`;
    }
    if (filterKey.startsWith("amenities")) {
        return `${getI18nExpByLang(i18next.language, `property.amenities.${filterKey.split(".")[1]}`)}...`;
    }
    if (filterKey.startsWith("features")) {
        return `${getI18nExpByLang(i18next.language, `property.features.${filterKey.split(".")[1]}`)}...`;
    }
    if (filterKey.startsWith("squareSurface")) {
        return `${value} ${unit}...`;
    }
    if (filterKey.startsWith("bySquareSurface")) {
        return `${value} ${unit}...`;
    }
    if (filterKey.startsWith(PROPERTY_FILTERS.STATUS)) {
        return `${getI18nExpByLang(i18next.language, `searchBar.filter.status.${value}`)}...`;
    }
    if (
        filterKey.startsWith(PROPERTY_FILTERS.DELIVERY_TYPE_START) ||
        filterKey.startsWith(PROPERTY_FILTERS.DELIVERY_TYPE_END)
    ) {
        return `${dayjs(value).format("MMM DD")}...`;
    }
    if (filterKey.startsWith("price.max") && unit) {
        const symbole = getI18nExpByLang(i18next.language, "searchBar.currencies." + unit);
        const convertedPrice = parseInt(value);
        return `${symbole}${scientistNumber(convertedPrice, 1)}..`;
    }
    if (filterKey.startsWith("price.min") && unit) {
        const symbole = getI18nExpByLang(i18next.language, "searchBar.currencies." + unit);
        const convertedPrice = parseInt(value);
        return `${symbole}${scientistNumber(convertedPrice, 1)}..`;
    }
    return value;
}

export const updateSearchFilter = (
    listStore: ListStore<TPropertyListingMdl>,
    filters: TFilter[],
    reload = true,
): Promise<ListStore<TPropertyListingMdl>> => {
    return new Promise((resolve) => {
        const locationIndex = filters.findIndex((filter) => filter.id === "location");
        const newFiltersIds = filters.map((filter) => filter.id);
        listStore.updateFilters([
            ...listStore.filters
                .filter((filter) => {
                    if (locationIndex > -1) {
                        return filter.id !== "location";
                    }
                    return true;
                })
                .filter((filter) => !newFiltersIds.includes(filter.id)),
            ...filters.filter(
                (filter) =>
                    ((filter.id.startsWith("amenities") || filter.id.startsWith("features")) && filter.value) ||
                    !(filter.id.startsWith("amenities") || filter.id.startsWith("features")),
            ),
        ]);
        if (reload) listStore.reload();
        resolve(listStore);
    });
};

export function getUrlSearchFromFilter(filterType = FILTER_TYPE.default, search: string, filter?: TFilter) {
    const searchParams = new URLSearchParams(search);
    switch (filterType) {
        case FILTER_TYPE.propertyType:
            if (filter) {
                if (searchParams.has(filter.id)) {
                    searchParams.delete(filter.id);
                }
                searchParams.set(filter.id, filter.value.join(","));
            }
            return searchParams.toString();
        case FILTER_TYPE.price:
            if (filter) {
                if (searchParams.has(filter.id)) {
                    searchParams.delete(filter.id);
                    searchParams.delete("currency");
                }
                searchParams.set(filter.id, filter.value.toString());
                if (filter.id2) {
                    searchParams.set("currency", filter.id2);
                }
            }
            return searchParams.toString();
        case FILTER_TYPE.bySquareSurface:
            if (filter) {
                if (searchParams.has(filter.id)) {
                    searchParams.delete(filter.id);
                    searchParams.delete("currency");
                    searchParams.delete("unit");
                }
                searchParams.set(filter.id, filter.value.toString());
                if (filter.id2) {
                    const currency = filter.id2.split("/")[1] as CURRENCY_OPTIONS;
                    const squareUnit = filter.id2.split("/")[0] as "m" | "ft";
                    searchParams.set("currency", currency);
                    searchParams.set("unit", squareUnit);
                }
            }
            return searchParams.toString();
        case FILTER_TYPE.squareSurface:
            if (filter) {
                if (searchParams.has(filter.id)) {
                    searchParams.delete(filter.id);
                    searchParams.delete("unit");
                }
                searchParams.set(filter.id, filter.value.toString());
                if (filter.id2) {
                    searchParams.set("unit", filter.id2);
                }
            }
            return searchParams.toString();
        case FILTER_TYPE.default:
            if (filter) {
                if (searchParams.has(filter.id)) {
                    searchParams.delete(filter.id);
                }
                if (filter.value === PHASE_STATUS.NONE) {
                    searchParams.delete("inventory.status");
                    searchParams.delete("inventory.startDate");
                    searchParams.delete("inventory.endDate");
                    return searchParams.toString();
                }
                searchParams.set(filter.id, filter.value.toString());
            }
            return searchParams.toString();
        default:
            return searchParams.toString();
    }
}

export function getUrlSearchFromFilters(filters: TFilter[]) {
    let search = "";
    filters.forEach((filter) => {
        switch (filter.id) {
            case PROPERTY_FILTERS.PURPOSE:
            case PROPERTY_FILTERS.PUBLISHED:
            case "status":
            case PROPERTY_FILTERS.LOCATION:
                break;
            case PROPERTY_FILTERS.TYPE:
                search = getUrlSearchFromFilter(FILTER_TYPE.propertyType, search, filter);
                break;
            case PROPERTY_FILTERS.PRICE_MAX:
            case PROPERTY_FILTERS.PRICE_MIN:
                search = getUrlSearchFromFilter(FILTER_TYPE.price, search, filter);
                break;
            case PROPERTY_FILTERS.BY_SQUARE_SURFACE_MAX:
            case PROPERTY_FILTERS.BY_SQUARE_SURFACE_MIN:
                search = getUrlSearchFromFilter(FILTER_TYPE.bySquareSurface, search, filter);
                break;
            case PROPERTY_FILTERS.SQUARE_SURFACE_MAX:
            case PROPERTY_FILTERS.SQUARE_SURFACE_MIN:
                search = getUrlSearchFromFilter(FILTER_TYPE.squareSurface, search, filter);
                break;
            default:
                search = getUrlSearchFromFilter(FILTER_TYPE.default, search, filter);
        }
    });
    return search;
}
