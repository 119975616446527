import { action, computed, extendObservable, observable } from "mobx";
import { IListProvider } from "shared/_common/list/IListProvider";
import { LoadingStateMdl } from "shared/_common/loaders/_models/LoadingStateMdl";
import { createLoadingStateFromPromise } from "shared/_common/loaders/loadingStateUtils";
import { ObservableArray } from "mobx/lib/types/observablearray";
import { TFilter } from "admin/_common/filters/TFilter";
import _ from "lodash";
import { TObjWithId } from "_common/types/GenericTypes";
import { DEFAULT_PAGE_SIZE, TListInitialState } from "_common/resources/BaseResourceStore";

export class ListStore<T extends TObjWithId> {
    @observable items: (T | undefined)[] = [];
    @observable count: number | undefined;
    @observable selectedPage: number;
    @observable pageSize: number;
    @observable pageSizeList: number[] = [2, 5, 10, 15, 20, 50];
    @observable sort: { [key: string]: number } | undefined;
    @observable filters: TFilter[] = [];

    readonly listId: string;
    @observable private loadingStates: {
        [page: number]: LoadingStateMdl<{ count: number; items: T[] }>;
    } = {};
    private readonly listProvider: IListProvider<T>;
    private resetAndReload = _.debounce(
        action(() => {
            this.reset();
            this.setSelectedPage(1);
        }),
        1000,
    );

    constructor(
        listId: string,
        listProvider: IListProvider<T>,
        initialState?: TListInitialState<T>,
        pageSize?: number,
        noInitialLoading?: boolean,
        initialFilters?: TFilter[],
        initialSort?: { [key: string]: number },
    ) {
        this.listId = listId;
        this.listProvider = listProvider;
        this.selectedPage = 1;
        this.pageSize = pageSize ?? DEFAULT_PAGE_SIZE;
        if (initialState) this.init(initialState);
        if (initialFilters && initialFilters.length > 0) this.filters = initialFilters;
        if (initialSort) this.sort = initialSort;
        if (!noInitialLoading) this.load();
    }

    @computed get paginatedItems() {
        const offset = (this.selectedPage - 1) * this.pageSize;
        return this.items.slice(offset, offset + this.pageSize);
    }

    @computed get currentLoadingState() {
        return this.loadingStates[this.selectedPage];
    }

    @computed get hasMorePage() {
        return this.count === undefined || this.items.length < this.count;
    }

    @computed
    get isCurrentPageExisting() {
        if (this.selectedPage === 1 || !this.selectedPage) return true;
        return this.count && this.count > (this.selectedPage - 1) * DEFAULT_PAGE_SIZE;
    }

    @action load(page: number = this.selectedPage, countForStats = true) {
        if (!this.loadingStates[page] || this.loadingStates[page].status === "IDLE") {
            const offset = (page - 1) * this.pageSize;
            if (this.items.length >= offset && this.items[offset]) {
                extendObservable(this.loadingStates, {
                    [page]: new LoadingStateMdl("SUCCEEDED"),
                });
            } else {
                const loadPromise = this.listProvider
                    .list(offset, this.pageSize, this.listId, this.sort, this.filters, countForStats)
                    .then(
                        action((result) => {
                            const { count, items } = result;
                            this.count = count;
                            if (offset >= this.items.length) {
                                this.items.push(
                                    ...new Array(offset + items.length - this.items.length).fill(undefined),
                                );
                            }
                            for (let i = this.items.length; i < offset + 1; i++) {
                                this.items.push(undefined);
                            }
                            for (let i = 0; i < items.length; i++) {
                                this.items[offset + i] = items[i];
                            }
                            this.loadingStates[page].setSuccess(result);
                            return result;
                        }),
                        (err) => this.loadingStates[page].setError(err),
                    );
                if (!this.loadingStates[page]) {
                    extendObservable(this.loadingStates, {
                        [page]: createLoadingStateFromPromise(loadPromise),
                    });
                } else {
                    this.loadingStates[page].startLoading(loadPromise as any);
                }
            }
        }
        return this.loadingStates[page];
    }

    getLoadingState(page: number) {
        if (!this.loadingStates[page]) {
            this.loadingStates[page] = new LoadingStateMdl<{ count: number; items: T[] }>();
        }
        return this.loadingStates[page];
    }

    getSync(itemId: string) {
        return this.items.find((item) => (item as any)?._id === itemId);
    }

    @action setPageSize(pageSize: number) {
        this.pageSize = pageSize;
        this.resetAndReload();
    }

    @action setSelectedPage(page: number) {
        this.selectedPage = page;
        return this.load(page);
    }

    @action setSort(sort: { [key: string]: number }) {
        this.sort = sort;
        this.resetAndReload();
    }

    @action updateSort(sort: { [key: string]: number }) {
        this.sort = sort;
    }

    getFilterByFilterId(filterId: string) {
        const filterIndex = this.getFilterIndex(filterId);
        if (filterIndex > -1) return this.filters[filterIndex];
        else return undefined;
    }

    getFilterIndex(filterId: string, value?: any) {
        return this.filters.findIndex(
            (filter) => filter.id === filterId && (value === undefined || filter.value === value),
        );
    }

    getFilterIndexStartBy(filterId: string, value?: any) {
        return this.filters.findIndex(
            (filter) => filter.id.startsWith(filterId) && (value === undefined || filter.value === value),
        );
    }

    @action updateFilter(filter: TFilter, index?: number) {
        if (index === undefined) this.filters.push(filter);
        else this.filters.splice(index, 1, filter);
    }

    @action updateFilterAndReload(filter: TFilter, index?: number) {
        this.updateFilter(filter, index);
        this.resetAndReload();
    }

    updateFilters(filters: TFilter[]) {
        this.filters = [];
        filters.forEach(
            action((filter) => {
                this.filters.push(filter);
            }),
        );
        this.setSelectedPage(1);
    }

    @action removeFilters() {
        this.filters.splice(0, this.filters.length);
    }

    @action removeFiltersAndReload() {
        this.filters.splice(0, this.filters.length);
        this.resetAndReload();
    }

    @action removeFilter(index: number) {
        this.filters.splice(index, 1);
    }

    @action removeFilterByFilterId(filterId: string) {
        while (this.getFilterIndex(filterId) > -1) {
            this.removeFilter(this.getFilterIndex(filterId));
        }
    }

    @action removeFilterByFilterIdStartBy(filterId: string) {
        while (this.getFilterIndexStartBy(filterId) > -1) {
            this.removeFilter(this.getFilterIndexStartBy(filterId));
        }
    }

    @action removeFilterAndReload(index: number) {
        this.removeFilter(index);
        this.resetAndReload();
    }

    @action reset() {
        for (const page of Object.keys(this.loadingStates)) {
            delete this.loadingStates[Number(page)];
        }
        ((this.items as unknown) as ObservableArray<void>).clear();
    }

    reload(countForStats = true) {
        this.reset();
        this.load(this.selectedPage, countForStats);
    }

    @action onCreate(item: T) {
        if (this.items.find((listItem) => listItem?._id === item._id)) {
            this.onUpdate(item);
            return;
        }
        this.items.unshift(item);
    }

    @action onUpdate(updatedItem: T) {
        if (this.items.find((item) => item?._id === updatedItem._id)) {
            this.items.splice(
                this.items.findIndex((item) => item?._id === updatedItem._id),
                1,
                updatedItem,
            );
        }
    }

    @action onDelete(itemId: string) {
        this.items.splice(
            this.items.findIndex((item) => item?._id === itemId),
            1,
        );
    }

    private init(initialState: TListInitialState<T>) {
        this.count = initialState.count;
        this.selectedPage = initialState.page ?? 1;
        const arrIdxPage = this.selectedPage - 1;
        const itemsOfSelectedPage = initialState.pages[arrIdxPage];

        for (let i = 0; i < arrIdxPage * this.pageSize; i++) {
            this.items.push(undefined);
            if (i % this.pageSize === 0) {
                this.loadingStates[i / this.pageSize + 1] = new LoadingStateMdl("IDLE");
            }
        }
        this.items.push(...itemsOfSelectedPage);
        this.loadingStates[this.selectedPage] = new LoadingStateMdl("SUCCEEDED");
    }
}
