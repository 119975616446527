import React from "react";
import { TCardProperty } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import arrow from "../../../assets/images/rightArrowTiny.svg";
import { cn } from "_configs/twconfig";

type Props = {
    property: Pick<TCardProperty, "nbOfUnitsFiltered" | "nbOfUnits">;
    className?: string;
};

export function PropertyCardHeader({ property, className }: Props) {
    const { t } = useTranslation();
    return (
        <div className={cn("flex flex-row justify-between", className)}>
            <div className={"text-sm"}>
                {property?.nbOfUnitsFiltered &&
                property?.nbOfUnitsFiltered > 0 &&
                property?.nbOfUnitsFiltered < property.nbOfUnits
                    ? t("property.unitsFiltered", { count: property.nbOfUnitsFiltered })
                    : t("property.unitsAvailable", { count: property.nbOfUnits })}
            </div>
            <div className={"flex flex-row items-center"}>
                {t("words.viewAll")}
                <img src={arrow} alt={"arrow"} className={"ml_5"} />
            </div>
        </div>
    );
}
