import React from "react";
import { TurnedInNot } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { userStore } from "users/_stores/userStore";
import { toast } from "react-toastify";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { Button } from "_common/ui/mui/buttons/Button";

export function SavedSearchButton() {
    const { t } = useTranslation();
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);
    return (
        <Button
            onClick={() => {
                const filters = JSON.parse(JSON.stringify(listStore.filters));
                userStore.isLogged
                    ? userStore
                          .patchSearchToFavorite(
                              filters,
                              "",
                              propertiesStore.addressParams.region,
                              propertiesStore.addressParams.city,
                              propertiesStore.addressParams?.neighbourhood,
                          )
                          .then(() => toast.success(t("searchBar.savedSearch")))
                          .catch((e) => toast.error(e))
                    : userStore.openSignInWithModal();
            }}
            color={"primary"}
            variant={"contained"}
        >
            <TurnedInNot className="mr_5" fontSize={"default"} /> {t("searchBar.saveSearch")}
        </Button>
    );
}
