import { BaseResourceStore, TInitialState } from "_common/resources/BaseResourceStore";
import { GEO_ZONE_TYPE, TGeoZoneMdl } from "geoZones/_models/GeoZoneMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import { action, computed, observable } from "mobx";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { getResourceInitialStateValue } from "_common/_utils/initialStateUtils";
import { removeAccentFromString } from "_common/_utils/alphaNumUtils";
import _ from "lodash";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { WithRequiredProperty } from "_common/types/GenericTypes";
import slugify from "slugify";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { getFiltersOfGeozoneFromUrl } from "_common/_utils/geoZoneUtils";
import { TLang } from "_configs/sharedConfig";

export class GeoZonesStore extends BaseResourceStore<WithRequiredProperty<TGeoZoneMdl, "_id">> {
    @observable geoZone: TGeoZoneMdl | undefined;
    @observable geoZoneState: LoadingStateMdl<TGeoZoneMdl | undefined> = new LoadingStateMdl<TGeoZoneMdl | undefined>(
        "IDLE",
    );

    @observable search = "";
    @observable selectedIndex = -1;
    @observable geoZones: TGeoZoneMdl[] = [];
    @observable geoZonesState: LoadingStateMdl<TGeoZoneMdl[]> = new LoadingStateMdl<TGeoZoneMdl[]>();
    @observable sortedChildGeoZones:
        | Pick<TGeoZoneMdl, "properties" | "_id" | "address" | "type" | "name" | "image">[]
        | undefined = undefined;
    @observable sortedNearbyGeoZones:
        | Pick<TGeoZoneMdl, "properties" | "_id" | "address" | "type" | "name" | "image">[]
        | undefined = undefined;
    @observable sortedChildGeoZonesState: LoadingStateMdl<
        Pick<TGeoZoneMdl, "properties" | "_id" | "address" | "type" | "name" | "image">[]
    > = new LoadingStateMdl();
    @observable sortedNearbyGeoZonesState: LoadingStateMdl<
        Pick<TGeoZoneMdl, "properties" | "_id" | "address" | "type" | "name" | "image">[]
    > = new LoadingStateMdl();

    constructor() {
        super("geoZones");
        this.onInit();
    }

    @computed get address() {
        return {
            city: this.geoZone?.address?.city ?? undefined,
            province: this.geoZone?.address?.province ?? undefined,
            provinceLong: this.geoZone?.address?.provinceLong ?? undefined,
            neighbourhood: this.geoZone?.address?.neighbourhood ?? undefined,
        };
    }

    @computed get isType() {
        return {
            isCity: this.geoZone?.type === GEO_ZONE_TYPE.CITY,
            isProvince: this.geoZone?.type === GEO_ZONE_TYPE.PROVINCE,
            isNeighbourhood: this.geoZone?.type === GEO_ZONE_TYPE.NEIGHBORHOOD,
            isCommunity: !!this.geoZone?.isCommunity,
        };
    }

    @computed get isSearching() {
        return this.search.length > 1;
    }

    @action setSearch = _.debounce((s: string) => {
        this.search = s;
        this.fetchGeozones(slugify(s), 5);
        propertiesStore.fetchPropertiesForSearch(removeAccentFromString(s), 5);
    }, 400);

    @action resetGeoZone() {
        this.geoZone = undefined;
        this.geoZoneState.setStatus("IDLE");
    }

    @action setGeoZone(geoZone: TGeoZoneMdl) {
        this.geoZone = geoZone;
    }

    @computed get selectedResult() {
        return this.geoZones[this.selectedIndex];
    }

    @action setSelectedIndex(index: number) {
        this.selectedIndex = index;
    }

    @action pressArrowDown() {
        if (this.geoZones) {
            if (this.selectedIndex === this.geoZones.length - 1) {
                this.selectedIndex = 0;
            } else {
                this.selectedIndex += 1;
            }
        }
    }

    @action pressArrowUp() {
        if (this.geoZones) {
            if (this.selectedIndex <= 0) {
                this.selectedIndex = this.geoZones.length - 1;
            } else {
                this.selectedIndex -= 1;
            }
        }
    }

    async setGeoZoneFromUrl(url: string) {
        const splittedUrl = url.split("/");
        const lang = splittedUrl[1];
        const filters = getFiltersOfGeozoneFromUrl(splittedUrl.slice(3), lang as TLang);
        if (!filters.length) return;
        const { items } = await this.list(undefined, undefined, undefined, undefined, filters);
        this.geoZone = items[0];
        return items[0] as TGeoZoneMdl;
    }

    protected onInit(_fromRootCtor?: boolean) {
        this.geoZoneState.startLoading();
        const initialState = getResourceInitialStateValue("geoZone") as
            | TInitialState<WithRequiredProperty<TGeoZoneMdl, "_id">>
            | undefined;
        if (initialState) {
            this.geoZoneState.setSuccess(this.geoZone);
            this.geoZone = initialState.items[0];
        } else this.geoZoneState.setStatus("IDLE");
    }

    fetchGeozones(value?: string, limit = 10) {
        if (!this.geoZoneState.isLoading) {
            this.geoZonesState.startLoading();

            const filtersParam = value
                ? `&filters=${JSON.stringify([{ id: "slug", type: TFilterType.STRING, value }])}`
                : "";
            const url = `${this.apiPath}/listing?limit=${limit}${filtersParam}`;
            return fetchUtils.get<{ items: TGeoZoneMdl[] }>(url).then(
                action(({ data: { items } }) => {
                    this.geoZones = items;
                    this.geoZonesState.setSuccess(items);
                }),
            );
        }
    }

    fetchSortedNeighborhoods(typeOrOptions?: PROPERTY_TYPE | "beachfront" | "luxury") {
        if (!this.sortedChildGeoZonesState.isLoading) {
            this.sortedChildGeoZonesState.startLoading();
            const geoZoneId = this.geoZone?._id ? `/${this.geoZone._id}` : "";
            const url = `${this.apiPath}/childGeoZones/${typeOrOptions ?? "generic"}${geoZoneId}`;
            const promise = fetchUtils.get<TGeoZoneMdl[]>(url);
            promise
                .then(
                    action(({ data }) => {
                        this.sortedChildGeoZones = data;
                        this.sortedChildGeoZonesState.setSuccess(data);
                    }),
                )
                .catch((e) => console.error(e));
        }
        return this.sortedChildGeoZonesState;
    }

    fetchNearbyGeoZones(typeOrOptions?: PROPERTY_TYPE | "beachfront" | "luxury") {
        if (!this.sortedNearbyGeoZonesState.isLoading) {
            this.sortedNearbyGeoZonesState.startLoading();
            const geoZoneId = this.geoZone?._id ? `/${this.geoZone._id}` : "";
            const url = `${this.apiPath}/nearbyGeoZones/${typeOrOptions ?? "generic"}${geoZoneId}`;
            const promise = fetchUtils.get<TGeoZoneMdl[]>(url);
            promise
                .then(
                    action(({ data }) => {
                        this.sortedNearbyGeoZones = data;
                        this.sortedNearbyGeoZonesState.setSuccess(data);
                    }),
                )
                .catch((e) => console.error(e));
        }
        return this.sortedNearbyGeoZonesState;
    }

    protected onReset() {
        this.geoZone = undefined;
        this.geoZoneState = new LoadingStateMdl<TGeoZoneMdl | undefined>("IDLE");
        this.sortedChildGeoZonesState = new LoadingStateMdl<
            Pick<TGeoZoneMdl, "properties" | "_id" | "address" | "type" | "name" | "image">[]
        >("IDLE");
        this.sortedChildGeoZones = [];
        this.sortedNearbyGeoZones = [];
        this.cache = {};
        this.listsStores = {};
        this.onInit();
    }
}

export const geoZonesStore = new GeoZonesStore();
