import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { numberFormatter } from "_common/_utils/currencyUtils";

export const PropertiesCount = observer(() => {
    const { t } = useTranslation();
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);
    useEffect(() => {
        void propertiesStore.getUnitsCount(listStore.filters);
    }, [listStore.filters]);
    return (
        <div className={"text-xs font-light flex items-center gap-1"}>
            <div>
                {t("propertiesPage.listingNumber", {
                    count: listStore.count,
                })}
            </div>
            <div>/</div>
            <div>
                {t("propertiesPage.listingUnitsMatched", {
                    number: numberFormatter(propertiesStore.countUnits),
                })}
            </div>
        </div>
    );
});
