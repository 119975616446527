import { GEO_ZONE_TYPE, TGeoZoneMdl } from "geoZones/_models/GeoZoneMdl";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { reformatStringForUrls, reformatUrlForOriginalName } from "_common/_utils/alphaNumUtils";
import { URLS } from "_configs/URLS";
import i18next from "i18next";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { PARAMS_NORTH_PREFIX, PARAMS_PAGE_PREFIX, PARAMS_ZOOM_PREFIX } from "_common/_utils/searchUtils";
import { isPropertyType } from "properties/searchBar/filters/_utils/filtersUtils";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import { TLang } from "_configs/sharedConfig";
import { TFilter } from "admin/_common/filters/TFilter";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";

export function getUrlLinkGeoZone(
    geoZone?: TGeoZoneMdl | Pick<TGeoZoneMdl, "type" | "address">,
    typeOrOptions?: PROPERTY_TYPE | "beachfront" | "luxury",
) {
    const typeOrOptionsParam = typeOrOptions ? i18next.t("property.typesUrl." + typeOrOptions) : undefined;
    const paramskey =
        typeOrOptions === "beachfront"
            ? "?features.beachfront=true"
            : typeOrOptions === "luxury"
            ? "?features.luxury=true"
            : `?type=${typeOrOptionsParam}`;

    if (!geoZone) {
        return reformatStringForUrls(URLS.buy(i18next.language, typeOrOptionsParam));
    }
    switch (geoZone.type) {
        case GEO_ZONE_TYPE.NEIGHBORHOOD:
            return reformatStringForUrls(
                `${URLS.buy(
                    i18next.language,
                    geoZone?.address.province,
                    geoZone?.address.city,
                    geoZone.address.neighbourhood,
                )}${typeOrOptionsParam ? paramskey : ""}`,
            );
        case GEO_ZONE_TYPE.PROVINCE:
            return reformatStringForUrls(URLS.buy(i18next.language, geoZone?.address.provinceLong, typeOrOptionsParam));
        default:
            return reformatStringForUrls(
                URLS.buy(i18next.language, geoZone?.address.province, geoZone?.address.city, typeOrOptionsParam),
            );
    }
}

export function getLabelLinkGeoZone(
    addressParams: {
        region: string | undefined;
        regionLong: string | undefined;
        city: string | undefined;
        address: string | undefined;
        neighbourhood: string | undefined;
    },
    geoZone?: TGeoZoneMdl,
    type?: PROPERTY_TYPE,
) {
    if (!geoZone) {
        return i18next.t("propertiesPage.meta.geoZone.BUY.h1." + type, {
            geoZoneName: geoZonesStore.geoZone ? addressParams.city : "Mexico",
        });
    }

    switch (geoZone.type) {
        case GEO_ZONE_TYPE.NEIGHBORHOOD:
            return i18next.t("propertiesPage.meta.geoZone.BUY.h1.neighborhood." + type, {
                neighborhood: addressParams.neighbourhood,
                city: addressParams.city,
            });
        case GEO_ZONE_TYPE.PROVINCE:
            return i18next.t("propertiesPage.meta.geoZone.BUY.h1." + type, {
                geoZoneName: geoZonesStore.geoZone ? addressParams.regionLong : "Mexico",
            });
        default:
            return i18next.t("propertiesPage.meta.geoZone.BUY.h1." + type, {
                geoZoneName: geoZonesStore.geoZone ? addressParams.city : "Mexico",
            });
    }
}

export function getPatternOfGeoZone(urlSplit: string[], lang: TLang) {
    const localizedBeachFront = reformatStringForUrls(getI18nExpByLang(lang, "property.features.beachfront"));
    const localizedLuxury = reformatStringForUrls(getI18nExpByLang(lang, "property.features.luxury"));

    const isProvincePagePattern =
        urlSplit.length >= 1 &&
        !urlSplit[0].startsWith(PARAMS_NORTH_PREFIX) &&
        !urlSplit[0].startsWith(PARAMS_PAGE_PREFIX) &&
        urlSplit[0] !== localizedBeachFront &&
        urlSplit[0] !== localizedLuxury &&
        urlSplit[0] !== "" &&
        !isPropertyType(urlSplit[0]);
    const isCityPagePattern =
        isProvincePagePattern &&
        urlSplit.length >= 2 &&
        !urlSplit[1].startsWith(PARAMS_NORTH_PREFIX) &&
        !urlSplit[1].startsWith(PARAMS_PAGE_PREFIX) &&
        urlSplit[1] !== localizedBeachFront &&
        urlSplit[1] !== localizedLuxury &&
        urlSplit[1] !== "" &&
        !isPropertyType(urlSplit[1]);

    const isNeighborhoodPagePattern =
        isCityPagePattern &&
        urlSplit.length >= 3 &&
        !isPropertyType(urlSplit[2]) &&
        urlSplit[2] !== localizedBeachFront &&
        urlSplit[2] !== localizedLuxury &&
        !urlSplit[2].startsWith(PARAMS_ZOOM_PREFIX) &&
        !urlSplit[2].startsWith(PARAMS_NORTH_PREFIX) &&
        !urlSplit[2].startsWith(PARAMS_PAGE_PREFIX);

    return {
        isProvincePagePattern,
        isCityPagePattern,
        isNeighborhoodPagePattern,
        localizedBeachFront,
        localizedLuxury,
    };
}

export function getFiltersOfGeozoneFromUrl(urlSplit: string[], lang: TLang) {
    const { isProvincePagePattern, isCityPagePattern, isNeighborhoodPagePattern } = getPatternOfGeoZone(urlSplit, lang);
    const filters: TFilter[] = [];
    if (isProvincePagePattern) {
        filters.push({
            id: "address.provinceLong",
            type: TFilterType.STRING,
            value: reformatUrlForOriginalName(urlSplit[0]),
        });
        filters.push({ id: "type", type: TFilterType.STRING, value: GEO_ZONE_TYPE.PROVINCE });
        if (isCityPagePattern) {
            filters.splice(0, 2);
            filters.push({
                id: "address.province",
                type: TFilterType.STRING,
                value: reformatUrlForOriginalName(urlSplit[0]),
            });
            filters.push({
                id: "address.city",
                type: TFilterType.STRING,
                value: reformatUrlForOriginalName(urlSplit[1]),
            });
            filters.push({ id: "type", type: TFilterType.STRING, value: GEO_ZONE_TYPE.CITY });
        }
        if (isNeighborhoodPagePattern) {
            filters.splice(2);
            filters.push({
                id: "address.neighbourhood",
                type: TFilterType.STRING,
                value: reformatUrlForOriginalName(urlSplit[2]),
            });
            filters.push({ id: "type", type: TFilterType.STRING, value: GEO_ZONE_TYPE.NEIGHBORHOOD });
        }
    }
    return filters;
}
