import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import houses from "./../../../shared/assets/images/propertyTypes/House.png";
import condos from "./../../../shared/assets/images/propertyTypes/Condo.png";
import villas from "./../../../shared/assets/images/propertyTypes/Villa.png";
import lands from "./../../../shared/assets/images/propertyTypes/Land.png";
import beachfront from "./../../../shared/assets/images/propertyTypes/Beachfront.png";
import luxury from "./../../../shared/assets/images/propertyTypes/Luxury.png";

export type THomeCard = {
    name: string | PROPERTY_TYPE | "beachfront" | "luxury";
    propertyType?: PROPERTY_TYPE | "beachfront" | "luxury";
    img?: string;
    nbOfProperties?: number;
    url?: string;
    type?: "propertyType" | "beachfront" | "geozone" | "luxury";
    titleLabelKey?: string;
};

export const PROPERTY_TYPES_WITH_BEACHFRONT: THomeCard[] & { name: "beachfront" | "luxury" | PROPERTY_TYPE }[] = [
    {
        name: PROPERTY_TYPE.land,
        titleLabelKey: "homePage.swiperLinks.propertyType." + PROPERTY_TYPE.land,
        propertyType: PROPERTY_TYPE.land,
        img: lands,
    },
    {
        name: PROPERTY_TYPE.villa,
        titleLabelKey: "homePage.swiperLinks.propertyType." + PROPERTY_TYPE.villa,
        propertyType: PROPERTY_TYPE.villa,
        img: villas,
    },
    {
        name: PROPERTY_TYPE.condo,
        titleLabelKey: "homePage.swiperLinks.propertyType." + PROPERTY_TYPE.condo,
        propertyType: PROPERTY_TYPE.condo,
        img: condos,
    },
    {
        name: PROPERTY_TYPE.house,
        titleLabelKey: "homePage.swiperLinks.propertyType." + PROPERTY_TYPE.house,
        propertyType: PROPERTY_TYPE.house,
        img: houses,
    },
    {
        name: "beachfront",
        titleLabelKey: "homePage.swiperLinks.propertyType.beachfront",
        propertyType: "beachfront",
        img: beachfront,
    },
    {
        name: "luxury",
        titleLabelKey: "homePage.swiperLinks.propertyType.luxury",
        propertyType: "luxury",
        img: luxury,
    },
];
