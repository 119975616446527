import React, { ReactNode } from "react";
import styles from "./_css/input.module.css";
import clsx from "clsx";
import { FieldError } from "react-hook-form";
import { useInput } from "_common/_utils/useInput";

export type TInputProps<T = string> = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> & {
    error?: FieldError;
    onValueChange?: (value: T, event?: React.ChangeEvent<HTMLInputElement>) => void;
    onBlurAlt?: (event: React.FocusEvent<HTMLInputElement>) => void;
    endIcon?: ReactNode;
    theme?: "white" | "gray" | "label";
    style?: any;
    hidden?: boolean;
    endIconClassName?: string;
    fullWidth?: boolean;
    onReset?: () => void;
};
/* eslint react/prop-types: 0 */
export const Input = React.forwardRef<HTMLInputElement, TInputProps>(
    (
        {
            hidden,
            error,
            className,
            endIcon,
            endIconClassName,
            onValueChange,
            onChange,
            fullWidth,
            onBlurAlt,
            style,
            theme,
            disabled,
            ...props
        },
        ref,
    ) => {
        useInput();
        return (
            <>
                <div
                    style={style}
                    className={clsx(styles.container, "flex items-center", {
                        ["hidden"]: hidden,
                        [styles.fullWith]: fullWidth,
                    })}
                >
                    <input
                        {...props}
                        className={clsx(
                            styles.input,
                            styles.input_default,
                            {
                                [styles.inputWithEndIcon]: !!endIcon,
                                [styles.input_label]: theme === "label",
                                [styles.input_white]: theme === "white",
                                [styles.input_gray]: theme === "gray" || disabled,
                                [styles.input_error]: error,
                            },
                            className,
                        )}
                        ref={ref}
                        onBlur={(e) => {
                            if (onBlurAlt) onBlurAlt(e);
                        }}
                        onChange={(event) => {
                            if (onChange) onChange(event);
                            if (onValueChange) onValueChange(event.target.value);
                        }}
                        onScroll={(e) => {
                            console.log("onScroll", e);
                            e.preventDefault();
                            e.stopPropagation();
                            return;
                        }}
                        onKeyUp={(key) => console.log(key)}
                    />
                    {endIcon && <div className={clsx(styles.endIcon, endIconClassName)}>{endIcon}</div>}
                </div>
                {/* eslint-disable-next-line react/prop-types */}
                {error && <div className={styles.error}>{error.message}</div>}
            </>
        );
    },
);
