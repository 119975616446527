import React from "react";
import { ContactUsForm } from "contact/ContactUsForm";
import { Dialog } from "_common/ui/dialogs/Dialog";

type Props = {
    isOpen: boolean;
    setIsOpen: () => void;
};

export function HomePageContactFormDialog(props: Props) {
    return (
        <Dialog open={props.isOpen} onClose={props.setIsOpen}>
            <ContactUsForm onClose={props.setIsOpen} />
        </Dialog>
    );
}
