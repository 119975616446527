import React, { lazy } from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { TPhase, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { RESOURCE, RESOURCE_KEY } from "admin/_common/photos/AdminPhotosStore";
import Suspenser from "_common/loaders/Suspenser";
import { PropertyGalleyConstructProgress } from "properties/gallery/PropertyGalleyConstructProgress";
import { PropertyOrUnitGalleryAndDocuments } from "properties/propertyPage/PropertyOrUnitGalleryAndDocuments";
import { GalleryProperty } from "gallery/GalleryProperty";
import { VideosGalleryProperty } from "gallery/videosGalleryProperty";
import { useTranslation } from "react-i18next";

type Props = {
    property: TPropertyListingMdl;
    propertyStore: ReturnType<typeof usePropertyStore>;
    propertyPhases: TPhase[];
};
const LazyPropertyOrUnitFloorPlans = lazy(() => import("properties/propertyPage/PropertyOrUnitFloorPlans"));

export function PropertyDevelopment({ property, propertyStore, propertyPhases }: Props) {
    const { t } = useTranslation();
    const displayPlan = propertyStore.property?.floorPlans?.length > 0 && !propertyStore.property?.hideInventory;
    const isPreviousProjects =
        (!!propertyStore.property?.previousProjects && propertyStore.property?.previousProjects.length > 0) ||
        (!!propertyStore.property?.previousProjectsVideos && propertyStore.property?.previousProjectsVideos.length > 0);

    return (
        <div className={"flex flex-col gap-8"}>
            {displayPlan && (
                <div>
                    <Suspenser>
                        <LazyPropertyOrUnitFloorPlans
                            propertyOrUnit={property}
                            resource={RESOURCE.PROPERTIES}
                            store={propertyStore}
                        />
                    </Suspenser>
                </div>
            )}
            {!!propertyStore.property.constructProgress && propertyStore.property.constructProgress.length > 0 && (
                <div>
                    <h2 className={"text-base font-normal text-[var(--color-primary)] pb-5"}>
                        {t("unitPage.gallery.tabs.CONSTRUCT_PROGRESS")}
                    </h2>
                    <PropertyGalleyConstructProgress
                        constructProgress={propertyStore.property.constructProgress}
                        propertyPhases={propertyPhases}
                    />
                </div>
            )}

            {!!propertyStore.property?.finishings && propertyStore.property?.finishings.length > 0 && (
                <div>
                    <h2 className={"text-base font-normal text-[var(--color-primary)] pb-5"}>
                        {t("propertyPage.gallery.tabs.FINISHINGS")}
                    </h2>
                    <PropertyOrUnitGalleryAndDocuments
                        resource={RESOURCE.PROPERTIES}
                        store={propertyStore}
                        resourceKey={RESOURCE_KEY.FINISHINGS}
                    />
                </div>
            )}

            {isPreviousProjects && (
                <div>
                    <h2 className={"text-base font-normal text-[var(--color-primary)] pb-5"}>
                        {t("propertyPage.gallery.tabs.PREVIOUS_PROJECTS")}
                    </h2>
                    <GalleryProperty
                        resource={RESOURCE.PROPERTIES}
                        store={propertyStore}
                        imageResource={RESOURCE_KEY.PREVIOUS_PROJECTS}
                    />
                    <VideosGalleryProperty />
                </div>
            )}
        </div>
    );
}
