import React from "react";
import { TPhase } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import checkCircle from "../../assets/images/icons/checkCircle.svg";
import dayjs from "dayjs";
import { PHASE_STATUS } from "admin/phases/_models/PhasesMdl";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
    phases: TPhase[];
};

export function PropertyPhases(props: Props) {
    const { t } = useTranslation();

    return (
        <div className="border rounded-lg pl-[23px] py-5 space-y-1.5 border-[var(--color-separator)]">
            {props.phases.map((phase, index) => {
                const date = dayjs(phase.deliveryDate);
                const month = date.format("MMMM").toLowerCase();
                const year = date.format("YYYY");
                return (
                    <div key={index} className="flex items-center text-sm">
                        <div className="flex-shrink-0">
                            <LazyLoadImage src={checkCircle} alt="check icon" />
                        </div>
                        <div className="ml-3">
                            <div>
                                {t("propertyPage.phases.phase")} {phase.numberPhase} -{" "}
                                {phase.status === PHASE_STATUS.READY_TO_MOVE_IN
                                    ? t("propertyPage.phases.delivered")
                                    : `${t("propertyPage.phases.deliveryIn")} ${t(`months.${month}`)} ${year}`}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
