import React from "react";
import { ListStorePaginator } from "admin/_common/list/ListStorePaginator";
import { useTranslation } from "react-i18next";
import { usePropertiesStore } from "properties/PropertiesPageContext";

export function PropertiesViewPagination() {
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);
    const start = listStore.selectedPage * (listStore.pageSize ?? 1);
    const displayCount = listStore.pageSize < (listStore.count ?? 1);
    const { t } = useTranslation();
    return (
        <div id={"PropertiesViewListPaginator"} className={"flex flex-col items-center mt-10"}>
            <ListStorePaginator listStore={listStore} shape={"rounded"} variant={"outlined"} color={"secondary"} />
            {displayCount && (
                <div className={"mt-2 text-center text-xs text-[color:var(--color-text-muted)]"}>
                    {t("propertiesPage.results", {
                        start: start - listStore.pageSize + 1,
                        end: start > (listStore.count ?? 1) ? listStore.count : start,
                        count: listStore.count,
                    })}
                </div>
            )}
        </div>
    );
}
