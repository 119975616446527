import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "_common/ui/forms/Input";
import { TextArea } from "_common/ui/forms/TextArea";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { TContactForm } from "components/misc/contactUs/ContactFormComponent";
import { userStore } from "users/_stores/userStore";
import i18next from "i18next";
import { ContactFormBtn } from "components/misc/contactUs/ContactFormBtn";
import { AC_TAGS } from "users/_models/UserMdl";
import { InputPhone } from "_common/ui/forms/InputPhone";
import { HiddenFieldsAttributerForm } from "components/form/HiddenFieldsAttributerForm";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { MenuItem } from "@material-ui/core";
import { Inquiry } from "components/misc/contactUs/Inquiry";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Checkbox } from "_common/ui/mui/checkbox/Checkbox";
import { useWindowSize } from "_common/_utils/hookWindow";
import CloseIcon from "@material-ui/icons/Close";

export const PHONE = "1-877-515 (4752)";
export const WHATSAPP_PHONE = "+1-514-932-5688";

type Props = {
    className?: string;
    isBlogPage?: boolean;
    onClose?: () => void;
};

export function ContactUsForm({ className, isBlogPage, onClose }: Props) {
    const { t } = useTranslation();

    const form = useForm<TContactForm>({
        defaultValues: {
            name: userStore.isLogged ? userStore.user?.firstName + " " + userStore.user?.lastName : "",
            phone: userStore.isLogged ? userStore.user?.phone : "",
            email: userStore.isLogged ? userStore.user?.email : "",
            text: "",
            subject: "",
            inquiry: "",
            workingWithAnAgent: "",
            newsletter: false,
        },
        mode: "onBlur",
    });

    const onAgentChange = (value: string) => {
        form.setValue("workingWithAnAgent", value);
    };

    const onNewsletterChange = (value: boolean) => {
        form.setValue("newsletter", value);
    };

    const size = useWindowSize();

    return (
        <FormProvider {...form}>
            <div
                className={`${className} p-5 flex-3 shadow-[0px_0px_10px_0px_#1A3A4540] rounded-[var(--border-radius)] h-fit md:mt-0`}
            >
                <div className={"flex flex-col flex-1"}>
                    {onClose && (
                        <div className={"flex justify-end cursor-pointer"}>
                            <CloseIcon onClick={onClose} />
                        </div>
                    )}
                    {isBlogPage && <h2 className={"text-xl text-[var(--color-primary)] mb-4"}>{t("words.help")}</h2>}
                    <div>
                        <InputBlock label={t("contactPage.form.name")} required />
                        <Controller
                            as={Input}
                            name="name"
                            control={form.control}
                            className={"!border !border-[var(--color-border)] !rounded-[var(--border-radius)]"}
                            placeholder={t("contactPage.form.name") + (size.width < 680 ? "*" : "")}
                        />
                        <InputBlock label={t("contactPage.form.email")} required className={"mt-[23px]"} />
                        <Controller
                            as={Input}
                            name="email"
                            control={form.control}
                            className={"!border !border-[var(--color-border)] !rounded-[var(--border-radius)]"}
                            placeholder={t("contactPage.form.email") + (size.width < 680 ? "*" : "")}
                            required
                            rules={{
                                required: t<string>("errors.forms.required"),
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: t("errors.forms.emailInvalid"),
                                },
                            }}
                        />
                        <InputBlock label={t("contactPage.form.phone")} required className={"mt-[23px]"} />
                        <Controller
                            as={InputPhone}
                            name="phone"
                            control={form.control}
                            placeholder={t("contactPage.form.phone")}
                            className={"!border !border-[var(--color-border)] !rounded-[var(--border-radius)]"}
                        />
                        {!isBlogPage && (
                            <div>
                                <InputBlock label={t("contactPage.form.inquiry")} required className={"mt-[23px]"} />
                                <Controller
                                    as={
                                        <UiSelect className={"w-full"}>
                                            {Inquiry[i18next.language].map((object, key) => (
                                                <MenuItem value={object} key={key}>
                                                    {object}
                                                </MenuItem>
                                            ))}
                                        </UiSelect>
                                    }
                                    defaultValue={Inquiry[i18next.language][0]}
                                    name="inquiry"
                                    control={form.control}
                                />
                            </div>
                        )}
                        {form.watch("inquiry") === Inquiry[i18next.language][0] && (
                            <div className={"mt-[23px]"}>
                                <div>{t("contactPage.form.agentQuestion")}</div>
                                <Controller
                                    as={
                                        <>
                                            <Checkbox
                                                checked={form.watch("workingWithAnAgent") === "yes"}
                                                onChange={() => onAgentChange("yes")}
                                            />
                                            <label className="mr-2">{t("words.yes")}</label>
                                            <Checkbox
                                                checked={form.watch("workingWithAnAgent") === "no"}
                                                onChange={() => onAgentChange("no")}
                                            />
                                            <label className="mr-2">{t("words.no")}</label>
                                            <Checkbox
                                                checked={form.watch("workingWithAnAgent") === "I am an agent"}
                                                onChange={() => onAgentChange("I am an agent")}
                                            />
                                            <label className="mr-2">{t("contactPage.form.iAmAgent")}</label>
                                        </>
                                    }
                                    name="workingWithAnAgent"
                                    control={form.control}
                                />
                            </div>
                        )}
                    </div>
                    <div>
                        <InputBlock label={t("contactPage.form.message")} required className={"mt-[23px]"} />
                        <Controller
                            as={TextArea}
                            name="text"
                            control={form.control}
                            rows={5}
                            className={
                                "!border !border-[var(--color-border)] !rounded-[var(--border-radius)] mt-[23px] md:mt-0"
                            }
                            placeholder={"Message" + (size.width < 680 ? "*" : "")}
                        />
                    </div>
                </div>
                <div className="flex items-center mt-4">
                    <Controller
                        name="newsletter"
                        control={form.control}
                        render={({ onChange, value }) => (
                            <Checkbox
                                checked={value}
                                onChange={(e) => {
                                    onChange(e.target.checked);
                                    onNewsletterChange(e.target.checked);
                                }}
                            />
                        )}
                    />
                    <label className="ml-2 mt-[23px] max-w-[350px]">{t("contactPage.form.newsletter")}</label>
                </div>
                <div className={"flex-1 flex flex-col"}>
                    <ContactFormBtn acTags={AC_TAGS.L4_CONTACT_US} />
                </div>
            </div>
            <HiddenFieldsAttributerForm />
        </FormProvider>
    );
}
